import React, { useContext } from "react";
import { Route as RouterRoute, Navigate, useLocation } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const PrivateRoute = ({ element: Element, ...rest }) => {
  const { isAuth, loading } = useContext(AuthContext);
  const location = useLocation();

  if (loading) {
    return <BackdropLoading />;
  }

  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Element {...rest} />;
};

const PublicRoute = ({ element: Element, ...rest }) => {
  const { isAuth, loading } = useContext(AuthContext);
  const location = useLocation();

  if (loading) {
    return <BackdropLoading />;
  }

  if (isAuth) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Element {...rest} />;
};

export { PrivateRoute, PublicRoute };