import React from "react";

import { Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";

const useStyles = makeStyles(theme => ({
    ticketHeader: {
        display: "flex",
        alignItems: "center",  // Centraliza verticalmente
        background: theme.palette.mode === 'dark' ? "#202C33" : "#eee",
        flex: "none",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    tagsContainerWrapper: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        background: theme.palette.mode === 'dark' ? "#202C33" : "#eee",
		borderColor: "transparent",  // Borda transparente
    }
}));

const TicketHeader = ({ loading, children }) => {
	const classes = useStyles();

	return (
		<>
			{loading ? (
				<TicketHeaderSkeleton />
			) : (
				<Card square className={classes.ticketHeader}>
					{children}
					<div className={classes.tagsContainerWrapper}/>
				</Card>
			)}
		</>
	);
};

export default TicketHeader;
