import React, { useState, useEffect, useContext, useRef } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";

import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import IconButton from "@mui/material/IconButton";
import MoodIcon from "@mui/icons-material/Mood";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import MicIcon from "@mui/icons-material/Mic";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { FormControlLabel, Switch } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { isString, isEmpty, isObject, has } from "lodash";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DescriptionIcon from "@mui/icons-material/Description";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import PersonIcon from "@mui/icons-material/Person";
import StoreIcon from '@mui/icons-material/Store';
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import { EditMessageContext } from "../../context/EditingMessage/EditingMessageContext";

import useQuickMessages from "../../hooks/useQuickMessages";
import EmojiPicker from 'emoji-picker-react';
import { Theme } from 'emoji-picker-react';
import { Slide, Grow } from "@mui/material";

import Compressor from 'compressorjs';
import MarkdownWrapper from "../MarkdownWrapper";
import ContactSearchModal from "../ContactSearchModal"; // Importar o modal de busca de contatos

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  newMessageBox: {
    background: theme.palette.mode === 'dark' ? "#202C33" : "#eee",
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },
  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: theme.palette.mode === 'dark' ? "#2A3942" : theme.palette.background.paper,
    display: "flex",
    borderRadius: 20,
    flex: 1,
    overflow: "hidden",

  },
  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
    color: theme.palette.text.primary,
    maxHeight: "200px", // Limite máximo de altura
    overflow: "hidden", // Adicionado para permitir rolagem

  },
  sendMessageIcons: {
    color: theme.palette.text.secondary,
  },
  uploadInput: {
    display: "none",
  },
  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },
  audioLoading: {
    color: green[500],
    opacity: "70%",
  },
  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },
  cancelAudioIcon: {
    color: "red",
  },
  sendAudioIcon: {
    color: "green",
  },
  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
    background: theme.palette.mode === 'dark' ? "#202C33" : "#eee",
  },
  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: theme.palette.action.hover,
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },
  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    color: theme.palette.text.primary,
  },
  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },
  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },
  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  dragging: {
    border: "2px dashed #000",
    position: "relative",
  },
  dragOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.8)", // Alterar para uma cor mais escura
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
    fontSize: "1.5rem",
    color: "#fff", // Alterar a cor do texto para branco
    transition: "transform 0.3s ease-in-out",
    transform: "translateY(100%)",
  },
  dragOverlayVisible: {
    transform: "translateY(0)",
  },
  menuOption: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  menuIcon: {
    marginRight: "10px",
  },
  menuText: {
    color: theme.palette.text.primary,
  },
  menuWrapper: {
    position: "absolute",
    bottom: "60px",
    left: "10px",
    backgroundColor: theme.palette.mode === 'dark' ? "#202C33" : "#eee",
    boxShadow: theme.shadows[5],
    borderRadius: "10px",
    zIndex: 1000,
  },
  rotateIcon: {
    transition: "transform 0.3s ease-in-out",
  },
  rotateIconOpen: {
    transform: "rotate(135deg)",
  },
  rotateIconClose: {
    transform: "rotate(-135deg)",
  },
}));

const EmojiOptions = (props) => {
  const { disabled, showEmoji, setShowEmoji, handleAddEmoji } = props;
  const themeGlobal = useTheme();
  const classes = useStyles();
  const emojiPickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmoji(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowEmoji]);

  return (
    <>
      <IconButton
        aria-label="emojiPicker"
        component="span"
        disabled={disabled}
        onClick={() => setShowEmoji((prevState) => !prevState)}
        size="large"
      >
        <MoodIcon className={classes.sendMessageIcons} />
      </IconButton>
      <Grow in={showEmoji}>
        <div className={classes.emojiBox} ref={emojiPickerRef}>
          <EmojiPicker
            theme={themeGlobal.palette.mode === 'dark' ? Theme.DARK : Theme.LIGHT}
            onEmojiClick={(emojiObject) => handleAddEmoji({ native: emojiObject.emoji })} />
        </div>
      </Grow>
    </>
  );
};

const SignSwitch = (props) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { setSignMessage, signMessage } = props;

  if (isMdUp) {
    return (
      <FormControlLabel
        style={{ color: theme.palette.text.secondary }}
        label={i18n.t("messagesInput.signMessage")}
        labelPlacement="start"
        control={
          <Switch
            size="small"
            checked={signMessage}
            onChange={(e) => setSignMessage(e.target.checked)}
            color="primary"
          />
        }
      />
    );
  }
  return null;
};

const FileInput = (props) => {
  const { handleChangeMedias, disableOption, setInputMessage, signMessage, setSignMessage, setContactSearchModalOpen } = props;
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenuOpen]);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleQuickReply = () => {
    setMenuOpen(false);
    setInputMessage((prev) => {
      const newMessage = prev + "/";
      return newMessage;
    });
  };

  return (
    <>
      <IconButton
        aria-label="toggleMenu"
        component="span"
        disabled={disableOption()}
        onClick={toggleMenu}
        size="large"
        className={clsx(classes.rotateIcon, {
          [classes.rotateIconOpen]: menuOpen,
        })}
      >
        <AddIcon className={classes.sendMessageIcons} />
      </IconButton>
      <Grow in={menuOpen}>
        <div className={classes.menuWrapper} ref={menuRef}>
          <input
            multiple
            type="file"
            id="upload-button"
            disabled={disableOption()}
            className={classes.uploadInput}
            onChange={handleChangeMedias}
          />
          <label htmlFor="upload-button">
            <div className={classes.menuOption}>
              <DescriptionIcon className={classes.menuIcon} style={{ color: "#7f66ff" }} />
              <span className={classes.menuText}>Documento</span>
            </div>
            <div className={classes.menuOption}>
              <PhotoLibraryIcon className={classes.menuIcon} style={{ color: "#007bfc" }} />
              <span className={classes.menuText}>Fotos e Vídeos</span>
            </div>
          </label>
          <div className={classes.menuOption} onClick={() => setContactSearchModalOpen(true)}>
            <PersonIcon className={classes.menuIcon} style={{ color: "#ff2e74" }} />
            <span className={classes.menuText}>Contato</span>
          </div>
          <div className={classes.menuOption}>
            <StoreIcon className={classes.menuIcon} style={{ color: "#009de2" }} />
            <span className={classes.menuText}>Catálogo</span>
          </div>
          <div className={classes.menuOption} onClick={handleQuickReply}>
            <FlashOnIcon className={classes.menuIcon} style={{ color: "#ffbc38" }} />
            <span className={classes.menuText}>Resposta rápida</span>
          </div>
          {/* Adiciona a opção de assinatura 
           <div className={classes.menuOption}>
            <DriveFileRenameOutlineIcon className={classes.menuIcon} style={{ color: "#7f66ff" }} />
              <SignSwitch
                setSignMessage={setSignMessage}
                signMessage={signMessage}
              />
            </div>
          */}

        </div>
      </Grow>
    </>
  );
};

const ActionButtons = (props) => {
  const {
    inputMessage,
    loading,
    recording,
    ticketStatus,
    handleSendMessage,
    handleCancelAudio,
    handleUploadAudio,
    handleStartRecording,
  } = props;
  const classes = useStyles();
  if (inputMessage) {
    return (
      (<IconButton
        aria-label="sendMessage"
        component="span"
        onClick={handleSendMessage}
        disabled={loading}
        size="large">
        <SendIcon className={classes.sendMessageIcons} />
      </IconButton>)
    );
  } else if (recording) {
    return (
      (<div className={classes.recorderWrapper}>
        <IconButton
          aria-label="cancelRecording"
          component="span"
          fontSize="large"
          disabled={loading}
          onClick={handleCancelAudio}
          size="large">
          <HighlightOffIcon className={classes.cancelAudioIcon} />
        </IconButton>
        {loading ? (
          <div>
            <CircularProgress className={classes.audioLoading} />
          </div>
        ) : (
          <RecordingTimer />
        )}
        <IconButton
          aria-label="sendRecordedAudio"
          component="span"
          onClick={handleUploadAudio}
          disabled={loading}
          size="large">
          <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
        </IconButton>
      </div>)
    );
  } else {
    return (
      (<IconButton
        aria-label="showRecorder"
        component="span"
        disabled={loading || ticketStatus !== "open"}
        onClick={handleStartRecording}
        size="large">
        <MicIcon className={classes.sendMessageIcons} />
      </IconButton>)
    );
  }
};

const CustomInput = (props) => {
  const {
    loading,
    inputRef,
    ticketStatus,
    inputMessage,
    setInputMessage,
    handleSendMessage,
    handleInputPaste,
    disableOption,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [quickMessages, setQuickMessages] = useState([]);
  const [options, setOptions] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);

  const { user } = useContext(AuthContext);

  const { list: listQuickMessages } = useQuickMessages();

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const messages = await listQuickMessages({ companyId, userId: user.id });
      const options = messages.map((m) => {
        let truncatedMessage = m.message;
        if (isString(truncatedMessage) && truncatedMessage.length > 35) {
          truncatedMessage = m.message.substring(0, 35) + "...";
        }
        return {
          value: m.message,
          label: `/${m.shortcode} - ${truncatedMessage}`,
        };
      });
      setQuickMessages(options);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isString(inputMessage) &&
      !isEmpty(inputMessage) &&
      inputMessage.length > 0
    ) {
      const firstWord = inputMessage.charAt(0);
      setPopupOpen(firstWord.indexOf("/") > -1);

      const filteredOptions = quickMessages.filter(
        (m) => m.label.toLowerCase().indexOf(inputMessage.toLowerCase()) > -1
      );
      setOptions(filteredOptions);
    } else {
      setPopupOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputMessage, quickMessages]);

  const onKeyPress = (e) => {
    if (loading || e.shiftKey) return;
    else if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const onPaste = (e) => {
    if (ticketStatus === "open") {
      handleInputPaste(e);
    }
  };

  const renderPlaceholder = () => {
    if (ticketStatus === "open") {
      return i18n.t("messagesInput.placeholderOpen");
    }
    return i18n.t("messagesInput.placeholderClosed");
  };

  const setInputRef = (input) => {
    if (input) {
      input.focus();
      inputRef.current = input;
    }
  };

  return (
    (<div className={classes.messageInputWrapper}>
      <Autocomplete
        freeSolo
        open={popupOpen}
        id="grouped-demo"
        value={inputMessage}
        options={options}
        clearIcon={null}
        getOptionLabel={(option) => {
          if (isObject(option)) {
            return option.label;
          } else {
            return option;
          }
        }}
        onChange={(event, opt) => {
          if (isObject(opt) && has(opt, "value")) {
            setInputMessage(opt.value);
            setTimeout(() => {
              inputRef.current.scrollTop = inputRef.current.scrollHeight;
            }, 200);
          }
        }}
        onInputChange={(event, opt, reason) => {
          if (reason === "input") {
            setInputMessage(event.target.value);
          }
        }}
        onPaste={onPaste}
        onKeyPress={onKeyPress}
        style={{
          width: "100%",
        }}
        ListboxProps={{
          style: {
            backgroundColor: theme.palette.mode === 'dark' ? "#202C33" : "#eee",
          },
          sx: {
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.mode === 'dark' ? "#111b21" : "#fff",
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: theme.palette.mode === 'dark' ? "#202C33" : "#eee",
            },
          },
        }}
        renderOption={(props, option) => {
          const [label, text] = option.label.split(' - ');
          const labelEdit = label.replace("/", "");
          return (
            <li {...props}>
              <span style={{ fontWeight: 'bold' }}>{labelEdit}:</span>
              <span style={{ color: theme.palette.text.secondary, marginLeft: 5 }}>
                {text}
              </span>
            </li>
          );
        }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <TextField
              {...params.InputProps}
              {...rest}
              disabled={disableOption()}
              inputRef={setInputRef}
              placeholder={renderPlaceholder()}
              className={classes.messageInput}
              variant="standard"
              multiline // Adicionado para permitir múltiplas linhas
              maxRows={10} // Limite máximo de linhas
              InputProps={{
                disableUnderline: true,
                id: "message-input", // Adicionado ID para referência
              }}
            />
          );
        }}
      />
    </div>)
  );
};

const MessageInputCustom = (props) => {
  const { ticketStatus, ticketId } = props;
  const classes = useStyles();

  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const inputRef = useRef();
  const { setReplyingMessage, replyingMessage } =
    useContext(ReplyMessageContext);
  const { setEditingMessage, editingMessage } = useContext(
    EditMessageContext
  );
  const { user } = useContext(AuthContext);

  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
  const [contactSearchModalOpen, setContactSearchModalOpen] = useState(false); // Estado para controlar a abertura do modal de busca de contatos

  useEffect(() => {
    if (editingMessage) {
      if (signMessage && editingMessage.body.startsWith(`*${user.name}:*\n`)) {
        setInputMessage(editingMessage.body.substr(editingMessage.body.indexOf("\n") + 1));
      } else {
        setInputMessage(editingMessage.body);
      }
    }
  }, [replyingMessage, editingMessage, signMessage, user.name]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
      setEditingMessage(null);
    };
  }, [ticketId, setReplyingMessage, setEditingMessage]);

  // const handleChangeInput = e => {
  // 	if (isObject(e) && has(e, 'value')) {
  // 		setInputMessage(e.value);
  // 	} else {
  // 		setInputMessage(e.target.value)
  // 	}
  // };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", media.name);
    });

    try {
      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
    setMedias([]);
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "" || !ticketId) return; // Adiciona verificação
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
    };
    try {
      if (editingMessage !== null) {
        await api.post(`/messages/edit/${editingMessage.id}`, message);
      }
      else {
        await api.post(`/messages/${ticketId}`, message);
      }
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
    setEditingMessage(null);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `audio-record-site-${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const disableOption = () => {
    return loading || recording || ticketStatus !== "open";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const selectedMedias = Array.from(e.dataTransfer.files);
      setMedias(selectedMedias);
      e.dataTransfer.clearData();
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  useEffect(() => {
    const handleDocumentDragOver = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const handleDocumentDrop = (e) => {
      e.preventDefault();
      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const selectedMedias = Array.from(e.dataTransfer.files);
        setMedias(selectedMedias);
        e.dataTransfer.clearData();
      }
    };

    const handleDocumentDragLeave = (e) => {
      e.preventDefault();
      setIsDragging(false);
    };

    document.addEventListener("dragover", handleDocumentDragOver);
    document.addEventListener("drop", handleDocumentDrop);
    document.addEventListener("dragleave", handleDocumentDragLeave);

    return () => {
      document.removeEventListener("dragover", handleDocumentDragOver);
      document.removeEventListener("drop", handleDocumentDrop);
      document.removeEventListener("dragleave", handleDocumentDragLeave);
    };
  }, []);

  const handleSendContact = async (contact) => {
    const message = {
      fromMe: true,
      body: `*${user.name}:* Contato enviado.\n\n${contact.name}\n${contact.number}`,
      mediaUrl: "",
      quotedMsg: null,
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
      setContactSearchModalOpen(false);
    } catch (err) {
      toastError(err);
    }
  };

  const renderReplyingMessage = (message) => {
    return (
      (<div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          {replyingMessage && (
            <div className={classes.replyginMsgBody}>
              <span className={classes.messageContactName}>
                {i18n.t("messagesInput.replying")} {message.contact?.name}
              </span>
              <MarkdownWrapper>
                {message.body}
              </MarkdownWrapper>
            </div>
          )}
          {editingMessage && (
            <div className={classes.replyginMsgBody}>
              <span className={classes.messageContactName}>
                {i18n.t("messagesInput.editing")}
              </span>
              <MarkdownWrapper>
                {message.body}
              </MarkdownWrapper>
            </div>
          )}
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={() => {
            setReplyingMessage(null);
            setEditingMessage(null);
            setInputMessage("");
          }}
          size="large">
          <ClearIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>)
    );
  };

  if (medias.length > 0)
    return (
      (<Paper elevation={0} square className={classes.viewMediaInputWrapper}>
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={(e) => setMedias([])}
          size="large">
          <CancelIcon className={classes.sendMessageIcons} />
        </IconButton>
        {loading ? (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        ) : (
          <span>
            {medias[0]?.name}
            {/* <img src={media.preview} alt=""></img> */}
          </span>
        )}
        <IconButton
          aria-label="send-upload"
          component="span"
          onClick={handleUploadMedia}
          disabled={loading}
          size="large">
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </Paper>)
    );
  else {
    return (
      <Paper
        square
        elevation={0}
        className={clsx(classes.mainWrapper, { [classes.dragging]: isDragging })}
        id="drop-area"
      >
        <Slide direction="up" in={isDragging} mountOnEnter unmountOnExit>
          <div className={clsx(classes.dragOverlay, { [classes.dragOverlayVisible]: isDragging })}>
            <span>Solte o arquivo aqui para anexar</span>
            <AttachFileIcon style={{ fontSize: 50, marginLeft: 10 }} />

          </div>
        </Slide>
        {(replyingMessage && renderReplyingMessage(replyingMessage)) || (editingMessage && renderReplyingMessage(editingMessage))}
        <div className={classes.newMessageBox}>
          <EmojiOptions
            disabled={disableOption()}
            handleAddEmoji={handleAddEmoji}
            showEmoji={showEmoji}
            setShowEmoji={setShowEmoji}
          />
          <FileInput
            disableOption={disableOption}
            handleChangeMedias={handleChangeMedias}
            setInputMessage={setInputMessage} // Passa a função setInputMessage
            signMessage={signMessage}
            setSignMessage={setSignMessage}
            setContactSearchModalOpen={setContactSearchModalOpen} // Passa a função para abrir o modal de busca de contatos
          />
          <CustomInput
            loading={loading}
            inputRef={inputRef}
            ticketStatus={ticketStatus}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleSendMessage={handleSendMessage}
            handleInputPaste={handleInputPaste}
            disableOption={disableOption}
          />
          <ActionButtons
            inputMessage={inputMessage}
            loading={loading}
            recording={recording}
            ticketStatus={ticketStatus}
            disabeleOption={disableOption}
            handleSendMessage={handleSendMessage}
            handleCancelAudio={handleCancelAudio}
            handleUploadAudio={handleUploadAudio}
            handleStartRecording={handleStartRecording}
          />
        </div>
        <ContactSearchModal
          open={contactSearchModalOpen}
          onClose={() => setContactSearchModalOpen(false)}
          onSelectContact={handleSendContact}
        />
      </Paper>
    );
  }
};

export default MessageInputCustom;
