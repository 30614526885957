import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { makeStyles, useTheme } from "@mui/styles";
import {
  Paper,
  Tabs,
  Tab,
  Badge,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Chip,
  Fade,
  Slide, // Adicione esta importação
  Typography,
  FormControlLabel,
  Switch,
  Avatar,
  Popover
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AlarmIcon from "@mui/icons-material/Alarm";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import NewTicketModal from "../NewTicketModal";
import TicketsListCustom from "../TicketsListCustom";
import TabPanel from "../TabPanel";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import useTickets from "../../hooks/useTickets";
import { Can } from "../Can";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.mode === 'dark' ? "#111B21" : "#eee",

  },
  header: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.mode === 'dark' ? "#111B21" : "#eee",
    justifyContent: "space-between",
    padding: theme.spacing(2, 2),
    borderRadius: 0,
  },
  searchContainer: {
    padding: theme.spacing(0, 1), // Adiciona padding de 10px nas laterais
  },
  searchWrapper: {
    backgroundColor: theme.palette.mode === 'dark' ? "#202C33" : "#f5f5f5",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 1),
    borderRadius: 40,
  },
  searchIcon: {
    marginRight: theme.spacing(2),
  },
  searchInput: {
    flex: 1,
    borderRadius: 30,
  },
  chipsContainer: {
    padding: theme.spacing(0, 1, 1),
  },
  chipsWrapper: {
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    flexWrap: "wrap",
  },
  filterChipsWrapper: {
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    flexWrap: "nowrap",
    overflowX: "auto",
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Chrome, Safari, Opera
    },
    height: theme.spacing(10), // Aumenta a altura da linha
  },
  title: {
    margin: theme.spacing(1, 0, 1), // Reduz a margem superior e inferior
  },
  tabLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Centraliza o conteúdo
    padding: theme.spacing(0.5, 3),
    fontSize: "0.70rem",
    fontWeight: "normal"
    ,
    textTransform: "none",
    "& .MuiBadge-root": {
      verticalAlign: "middle", // Alinha o badge verticalmente
    },
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
      marginRight: theme.spacing(-0.5),
      verticalAlign: "middle", // Alinha o ícone verticalmente
    },
    transition: "background-color 0.3s ease, color 0.3s ease",
  },

  selectedTabLabel: {
    //  backgroundColor: theme.palette.primary.main,
    //  color: theme.palette.primary.contrastText,
  },
  iconButton: {
    borderRadius: "50%",
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    // backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const TicketsManagerTabs = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const [tab, setTab] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [showAllTickets, setShowAllTickets] = useState(false);
  const [searchMenuAnchor, setSearchMenuAnchor] = useState(null);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showQueueChips, setShowQueueChips] = useState(false);
  const [showTagChips, setShowTagChips] = useState(false);
  const [showUserChips, setShowUserChips] = useState(false);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);

  const { user } = useContext(AuthContext);

  const searchInputRef = useRef();

  const { tickets: openTickets, loading: loadingOpenTickets } = useTickets({ status: "open" });
  const { tickets: pendingTickets, loading: loadingPendingTickets } = useTickets({ status: "pending" });
  const { tickets: closedTickets, loading: loadingClosedTickets } = useTickets({ status: "closed" });

  const [filteredTickets, setFilteredTickets] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    setSelectedQueueIds(user.queues.map((queue) => queue.id)); // Set default queues
  }, [user]);

  useEffect(() => {
    setOpenCount(openTickets.length);
    setPendingCount(pendingTickets.length);
    setClosedCount(closedTickets.length);
  }, [openTickets, pendingTickets, closedTickets]);

  useEffect(() => {
    const filterTickets = () => {
      let tickets = [];
      if (tab === "open") {
        tickets = openTickets;
      } else if (tab === "pending") {
        tickets = pendingTickets;
      } else if (tab === "closed") {
        tickets = closedTickets;
      }

      if (selectedQueueIds.length > 0) {
        tickets = tickets.filter(ticket => selectedQueueIds.includes(ticket.queueId));
      }

      setFilteredTickets(tickets);
    };

    filterTickets();
  }, [tab, openTickets, pendingTickets, closedTickets, selectedQueueIds]);

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleSearchChange = (e) => {
    setSearchParam(e.target.value.toLowerCase());
  };

  const handleTabChange = (status) => {
    setTab(status);
  };

  //teste
  const [tabOpen, setTabOpen] = useState("open");
  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };
  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleSearchMenuOpen = (event) => {
    setSearchMenuAnchor(event.currentTarget);
  };

  const handleSearchMenuClose = () => {
    setSearchMenuAnchor(null);
  };

  const handleOpenNewTicket = () => {
    setNewTicketModalOpen(true);
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((u) => u.id);
    setSelectedUsers(users);
  };

  const toggleQueueSelection = (queueId) => {
    setSelectedQueueIds((prevSelected) =>
      prevSelected.includes(queueId)
        ? prevSelected.filter((id) => id !== queueId)
        : [...prevSelected, queueId]
    );
  };

  const handleFilterQueues = () => {
    setShowQueueChips(!showQueueChips);
    setShowTagChips(false);
    setShowUserChips(false);
  };

  const handleFilterTags = () => {
    setShowTagChips(!showTagChips);
    setShowQueueChips(false);
    setShowUserChips(false);
  };

  const handleFilterUsers = () => {
    setShowUserChips(!showUserChips);
    setShowQueueChips(false);
    setShowTagChips(false);
  };

  return (
    <Paper elevation={0} className={classes.ticketsWrapper}>

      <div className={classes.header}>
        {<h3 className={classes.title}>Atendimentos</h3>}
        <div>
          <Button
            onClick={handleOpenNewTicket}
          >
            <AddCommentOutlinedIcon />
          </Button>

          <Tooltip title='Usuário conectado'>

          <Chip
           // avatar={<Avatar alt={user.name} src={user.avatarUrl} />}
            label={user.name.split(" ")[0]}
            color="primary"
          //  variant="outlined"
          />
          </Tooltip>
          <IconButton onClick={handleSearchMenuOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={searchMenuAnchor}
            open={Boolean(searchMenuAnchor)}
            onClose={handleSearchMenuClose}
          >
            <MenuItem onClick={() => navigate("/contacts")}>Contatos</MenuItem>
            <MenuItem onClick={() => navigate("/tags")}>Etiquetas</MenuItem>
            <MenuItem onClick={() => navigate("/quick-messages")}>Respostas rápidas</MenuItem>
            <MenuItem onClick={() => navigate("/schedules")}>Agendar Mensagem</MenuItem>
            <MenuItem disabled style={{ color: theme.palette.text.secondary }}>Filtrar conversas por</MenuItem>
            <MenuItem onClick={handleFilterQueues}>Filas</MenuItem>
            <MenuItem onClick={handleFilterTags}>Etiquetas</MenuItem>
            <MenuItem onClick={handleFilterUsers}>Usuários</MenuItem>

          </Menu>
        </div>
      </div>

      <div className={classes.searchContainer}></div>
      <div className={classes.searchContainer}>
        <div className={classes.searchWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            placeholder={i18n.t("tickets.search.placeholder")}
            inputRef={searchInputRef}
            value={searchParam}
            onChange={handleSearchChange}
          />
          <Can
            role={user.profile}
            perform="tickets-manager:showall"
            yes={() => (
              <>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => setShowAllTickets((prevState) => !prevState)}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  {showAllTickets ? (
                    <GroupIcon />
                  ) : (
                    <PersonIcon />
                  )}
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography sx={{ p: 1 }}>
                    {showAllTickets ?  "Clique para ver apenas os seus atendimentos" : "Clique para ver os atendimentos de todos os usuários"}
                  </Typography>
                </Popover>
              </>
            )}
          />       
        </div>
      </div>

      {
        showQueueChips && (
          <div className={classes.chipsContainer}>
            <div className={classes.chipsWrapper}>
              {user.queues.map((queue) => (
                <Chip
                  key={queue.id}
                  label={queue.name}
                  //   onClick={() => toggleQueueSelection(queue.id)}
                  color={selectedQueueIds.includes(queue.id) ? "primary" : "default"}
                />
              ))}
            </div>
          </div>
        )
      }

      {showTagChips && (
        <div className={classes.chipsContainer}>
          <TagsFilter selectedTags={selectedTags} onFiltered={handleSelectedTags} />
        </div>
      )}

      {showUserChips && (
        <div className={classes.chipsContainer}>
          <UsersFilter selectedUsers={selectedUsers} onFiltered={handleSelectedUsers} />
        </div>
      )}

      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          //   indicatorColor="none"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              openCount > 0 ? (
                <Badge className={classes.badge} badgeContent={openCount} color="primary" anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }} sx={{ '& .MuiBadge-badge': { backgroundColor: '#25D366', color: 'white', marginLeft: 10 } }}>
                  <div className={`${classes.tabLabel} ${tabOpen === "open" ? classes.selectedTabLabel : ""}`}>
                    {i18n.t("ticketsList.assignedHeader")}
                  </div>
                </Badge>
              ) : (
                <div className={`${classes.tabLabel} ${tabOpen === "open" ? classes.selectedTabLabel : ""}`}>
                  {i18n.t("ticketsList.assignedHeader")}
                </div>
              )
            }
            value={"open"}
          />
          <Tab
            label={
              pendingCount > 0 ? (
                <Badge className={classes.badge} badgeContent={pendingCount} color="primary" anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }} sx={{ '& .MuiBadge-badge': { backgroundColor: '#FF5722', color: 'white', marginLeft: 12 } }}>
                  <div className={`${classes.tabLabel} ${tabOpen === "pending" ? classes.selectedTabLabel : ""}`}>
                    {i18n.t("ticketsList.pendingHeader")}
                  </div>
                </Badge>
              ) : (
                <div className={`${classes.tabLabel} ${tabOpen === "pending" ? classes.selectedTabLabel : ""}`}>
                  {i18n.t("ticketsList.pendingHeader")}
                </div>
              )
            }
            value={"pending"}
          />
          <Tab
            label={
              <div className={`${classes.tabLabel} ${tabOpen === "closed" ? classes.selectedTabLabel : ""}`}>
                {i18n.t("tickets.tabs.closed.title")}
              </div>
            }
            value={"closed"}
          />
        </Tabs>

        <TicketsListCustom
          key={`open`} // Adiciona uma chave única
          status="open"
          showAll={showAllTickets}
          selectedQueueIds={selectedQueueIds}
          searchParam={searchParam}
          tags={selectedTags}
          users={selectedUsers}
          updateCount={(count) => setOpenCount(count)}
          tickets={filteredTickets}
          style={applyPanelStyle("open")}
        />
        <TicketsListCustom
          key={`pending`} // Adiciona uma chave única
          status="pending"
          showAll={true} // Show all pending tickets
          selectedQueueIds={selectedQueueIds}
          searchParam={searchParam}
          tags={selectedTags}
          users={selectedUsers}
          updateCount={(count) => setPendingCount(count)}
          tickets={filteredTickets}
          style={applyPanelStyle("pending")}
        />
        <TicketsListCustom
          key={`closed`} // Adiciona uma chave única
          status="closed"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
          searchParam={searchParam}
          tags={selectedTags}
          users={selectedUsers}
          tickets={filteredTickets}
          style={applyPanelStyle("closed")}

        />
      </TabPanel>

      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={() => setNewTicketModalOpen(false)}
      />
    </Paper>
  );
};

export default TicketsManagerTabs;
