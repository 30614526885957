import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  outline: 'none',
  padding: theme.spacing(3), // Adicionado padding
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const AboutModal = ({ open, onClose }) => {
  const appVersion = process.env.REACT_APP_VERSION;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="about-modal-title"
      aria-describedby="about-modal-description"
    >
      <StyledBox>
        <Header>
          <Typography id="about-modal-title" variant="h6" component="h2">
            Sobre o Aplicativo
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Header>
        <Typography id="about-modal-description" sx={{ mt: 2 }}>
          Nome do Aplicativo: Ticket App
        </Typography>
        <Typography id="about-modal-description" sx={{ mt: 2 }}>
          Versão: {appVersion}
        </Typography>
        <Typography id="about-modal-description" sx={{ mt: 2 }}>
          Telefone para Suporte: 21 974080373
        </Typography>
        <Typography id="about-modal-description" sx={{ mt: 2 }}>
          Email para Suporte: suporte@tinex.app
        </Typography>
        <Typography id="about-modal-description" sx={{ mt: 2 }}>
          Mais informações: Este aplicativo foi desenvolvido para gerenciar tickets de suporte de forma eficiente e intuitiva.
        </Typography>
      </StyledBox>
    </Modal>
  );
};

export default AboutModal;