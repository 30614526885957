import axios from "axios";
import config from "./config";

const backendUrl = config ? config.REACT_APP_BACKEND_URL : process.env.REACT_APP_BACKEND_URL;

if (!backendUrl) {
  throw new Error("REACT_APP_BACKEND_URL não está definido no arquivo de configuração ou nas variáveis de ambiente.");
}

const api = axios.create({
	baseURL: backendUrl,
	withCredentials: true,
});

export const openApi = axios.create({
	baseURL: backendUrl
});

export default api;
