import React, { useState, useEffect } from "react";
import { Modal, TextField, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Autocomplete, useTheme } from "@mui/material";
import { Search } from "@mui/icons-material";
import api from "../services/api";
import toastError from "../errors/toastError";

const ContactSearchModal = ({ open, onClose, onSelectContact }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [contacts, setContacts] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchContacts = async () => {
      if (searchTerm) {
        try {
          const { data } = await api.get("/contacts", { params: { searchParam: searchTerm } });
          setContacts(data.contacts);
        } catch (err) {
          toastError(err);
        }
      } else {
        setContacts([]);
      }
    };
    fetchContacts();
  }, [searchTerm]);

  return (
    <Modal open={open} onClose={onClose}>
      <div style={{ padding: 20, backgroundColor: theme.palette.mode === 'dark' ? "#202C33" : "#eee", margin: "auto", marginTop: "10%", width: "50%", maxHeight: "80%", overflowY: "auto" }}>
        <Autocomplete
          freeSolo
          options={contacts}
          getOptionLabel={(option) => `${option.name} - ${option.number}`}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder="Buscar contato"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <IconButton>
                    <Search />
                  </IconButton>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <ListItem {...props} button onClick={() => onSelectContact(option)}>
              <ListItemAvatar>
                <Avatar src={option.profilePicUrl}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={option.name} secondary={option.number} />
            </ListItem>
          )}
        />
      </div>
    </Modal>
  );
};

export default ContactSearchModal;