import React, { useContext, useEffect, useReducer, useState, useCallback } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Badge,
  Menu,
  MenuItem,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import SpaceDashboardRoundedIcon from '@mui/icons-material/SpaceDashboardRounded';
import {
  DashboardOutlined as DashboardOutlinedIcon,
  WhatsApp as WhatsAppIcon,
  MoreVert as MoreVertIcon,
  EventAvailable as EventAvailableIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  List as ListIcon,
  People as PeopleIcon,
  Announcement as AnnouncementIcon,
} from "@mui/icons-material";
import SyncIcon from '@mui/icons-material/Sync';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import { styled } from "@mui/material/styles";
import WhatsappPopOver from "../components/WhatsappPopOver";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "& .MuiListItemIcon-root": {
    color: "#aebac1",
  },
  "&:hover .MuiListItemText-root": {
    display: "block",
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: "12px",
    padding: "0 8px",
  },
  "&.Mui-selected": {
    backgroundColor: "rgba(174, 186, 193, 0.2)",
    borderRadius: "50%",
  },
  "&.Mui-selected .MuiListItemIcon-root": {
    color: "rgba(174, 186, 193, 0.5)",
  },
  transition: "background-color 0.3s, color 0.3s",
}));

function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} />;
      }),
    [to]
  );

  return (
    <StyledListItem component={renderLink}>
      {<ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} />
    </StyledListItem>
  );
}

const MainListItems = ({ drawerClose, selectedIcon, setSelectedIcon }) => {
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const showCampaigns = true; // Defina a lógica para exibir campanhas

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedIcon("more");
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    setSelectedIcon("settings");
    navigate("/settings");
  };

  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <Slide direction="right" in={true} mountOnEnter unmountOnExit>
      <div onClick={drawerClose} style={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '8px' }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
          <Can
            role={user.profile}
            perform="dashboard:view"
            yes={() => (
              <IconButton
                component={RouterLink}
                to="/"
                className={selectedIcon === "dashboard" ? "selected" : ""}
                style={{
                  transition: "background-color 0.3s",
                  backgroundColor: selectedIcon === "dashboard" ? "rgba(174, 186, 193, 0.2)" : "transparent",
                  borderRadius: "50%"
                }}
                onClick={() => setSelectedIcon("dashboard")}
              >
                {selectedIcon === "dashboard" ? <SpaceDashboardRoundedIcon /> : <SpaceDashboardOutlinedIcon />}
              </IconButton>
            )}
          />

          {user.id &&

            <IconButton
              component={RouterLink}
              to="/tickets"
              className={selectedIcon === "tickets" ? "selected" : ""}
              onClick={() => handleIconClick("tickets")}
              style={{
                transition: "background-color 0.3s",
                backgroundColor: selectedIcon === "tickets" ? "rgba(174, 186, 193, 0.2)" : "transparent",
                borderRadius: "50%",
                padding: selectedIcon === "tickets" ? "-8px" : "0" // Adicione padding para ajustar o tamanho
              }}
            >
              <WhatsappPopOver notificationCount={notificationCount} setNotificationCount={setNotificationCount} />
            </IconButton>
          }
          <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (
              <IconButton
                component={RouterLink}
                to="/connections"
                className={selectedIcon === "connections" ? "selected" : ""}
                onClick={() => handleIconClick("connections")}
                style={{
                  transition: "background-color 0.3s",
                  backgroundColor: selectedIcon === "connections" ? "rgba(174, 186, 193, 0.2)" : "transparent",
                  borderRadius: "50%",
                }}
              >
                <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                  <SyncIcon />
                </Badge>
              </IconButton>
            )}
          />

          {user.id &&
            <IconButton
              onClick={handleMoreClick}
              className={selectedIcon === "more" ? "selected" : ""}
              style={{ transition: "background-color 0.3s" }}
            >
              <MoreHorizIcon />
            </IconButton>
          }
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMoreClose}
          >
            <MenuItem onClick={() => navigate("/todolist")}>
              {i18n.t("mainDrawer.listItems.todolist")}
            </MenuItem>
            <MenuItem onClick={() => navigate("/chats")}>
              {i18n.t("mainDrawer.listItems.chats")}
            </MenuItem>
            <MenuItem onClick={() => navigate("/helps")}>
              {i18n.t("mainDrawer.listItems.helps")}
            </MenuItem>
            <Can
              role={user.profile}
              perform="drawer-admin-items:view"
              yes={() => (
                <MenuItem onClick={() => navigate("/queues")}>
                  {i18n.t("mainDrawer.listItems.queues")}
                </MenuItem>
              )}
            />
            <Can
              role={user.profile}
              perform="drawer-admin-items:view"
              yes={() => (
                <MenuItem onClick={() => navigate("/users")}>
                  {i18n.t("mainDrawer.listItems.users")}
                </MenuItem>
              )}
            />
            <Can
              role={user.profile}
              perform="drawer-admin-items:view"
              yes={() => (
                <MenuItem onClick={() => navigate("/messages-api")}>
                  {i18n.t("mainDrawer.listItems.messagesAPI")}
                </MenuItem>
              )}
            />
            {showCampaigns && (
              <>
                <MenuItem onClick={() => setOpenCampaignSubmenu((prev) => !prev)}>
                {i18n.t("mainDrawer.listItems.campaigns")}
                
                  {openCampaignSubmenu ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </MenuItem>
                <Collapse
                  style={{ paddingLeft: 15 }}
                  in={openCampaignSubmenu}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem onClick={() => navigate("/campaigns")} button>
                      <ListItemIcon>
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText primary="Lista de Campanhas" />
                    </ListItem>
                    <ListItem
                      onClick={() => navigate("/contact-lists")}
                      button
                    >
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Listas de Contatos" />
                    </ListItem>
                    <ListItem
                      onClick={() => navigate("/campaigns-config")}
                      button
                    >
                      <ListItemIcon>
                        <SettingsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Configurações" />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
            {user.super && (
              <MenuItem onClick={() => navigate("/announcements")}>
               {i18n.t("mainDrawer.listItems.annoucements")}
                </MenuItem>
            )}
            <Can
              role={user.profile}
              perform="drawer-admin-items:view"
              yes={() => (
                <MenuItem onClick={() => navigate("/financeiro")}>
                  {i18n.t("mainDrawer.listItems.financeiro")}
                </MenuItem>
              )}
            />
          </Menu>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '8px 0' }}>
          {user.id &&
            <IconButton
              onClick={() => {
                setSelectedIcon("settings");
                navigate("/settings");
              }}
              style={{
                transition: "background-color 0.3s",
                backgroundColor: selectedIcon === "settings" ? "rgba(174, 186, 193, 0.2)" : "transparent",
                borderRadius: "50%"
              }}
              className={selectedIcon === "settings" ? "selected" : ""}
            >
              {selectedIcon === "settings" ? <SettingsIcon /> : <SettingsOutlinedIcon />}
            </IconButton>
          }
        </div>
      </div>
    </Slide>
  );
};

export default MainListItems;