import io from "socket.io-client";
import { isObject } from "lodash";
import config from "./config";

const backendUrl = config ? config.REACT_APP_BACKEND_URL : process.env.REACT_APP_BACKEND_URL;

if (!backendUrl) {
  throw new Error("REACT_APP_BACKEND_URL não está definido no arquivo de configuração ou nas variáveis de ambiente.");
}

const socket = io(backendUrl);

export function socketConnection(params) {
  let userId = null;
  if (localStorage.getItem("userId")) {
    userId = localStorage.getItem("userId");
  }
  return socket.connect({
    transports: ["websocket", "polling", "flashsocket"],
    pingTimeout: 18000,
    pingInterval: 18000,
    query: isObject(params) ? { ...params, userId } : { userId },
  });
}

export default socket;
