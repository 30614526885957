// src/App.js
import React, { useState, useEffect } from "react";
import RoutesComponent from "./routes";
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';

const App = () => {
  const [locale, setLocale] = useState(null);

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    if (i18nlocale) {
      setLocale(i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5));
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(locale)}>
        <CssBaseline />
          <RoutesComponent />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
