import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import TicketResponsiveContainer from "../pages/TicketResponsiveContainer";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import SettingsCustom from "../pages/SettingsCustom/";
import Financeiro from "../pages/Financeiro/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import Queues from "../pages/Queues/";
import Tags from "../pages/Tags/";
import MessagesAPI from "../pages/MessagesAPI/";
import Helps from "../pages/Helps/";
import ContactLists from "../pages/ContactLists/";
import ContactListItems from "../pages/ContactListItems/";
// import Companies from "../pages/Companies/";
import QuickMessages from "../pages/QuickMessages/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { TicketsContextProvider } from "../context/Tickets/TicketsContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Schedules from "../pages/Schedules";
import Campaigns from "../pages/Campaigns";
import CampaignsConfig from "../pages/CampaignsConfig";
import CampaignReport from "../pages/CampaignReport";
import Annoucements from "../pages/Annoucements";
import Chat from "../pages/Chat";
import Subscription from "../pages/Subscription/";
import ToDoList from "../pages/ToDoList/";

import { PrivateRoute, PublicRoute } from "./Route";
import { socketConnection } from "../services/socket";
import toastError from "../errors/toastError";

const RoutesComponent = () => {
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);

  useEffect(() => {
    const cshow = localStorage.getItem("cshow");
    if (cshow !== undefined) {
      setShowCampaigns(true);
    }

    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +userId) {
        toastError("Sua conta foi acessada em outro computador.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
    });

    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <TicketsContextProvider>
          <WhatsAppsProvider>
            <LoggedInLayout selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon}>
              <Routes>
                <Route path="/login" element={<PublicRoute element={Login} />} />
                <Route path="/signup" element={<PublicRoute element={Signup} />} />
                {/* <Route path="/create-company" element={<PublicRoute element={Companies} />} /> */}
                <Route path="/" element={<PrivateRoute element={Dashboard} />} />
                <Route path="/tickets/:ticketId?" element={<PrivateRoute element={TicketResponsiveContainer} />} />
                <Route path="/connections" element={<PrivateRoute element={Connections} />} />
                <Route path="/quick-messages" element={<PrivateRoute element={QuickMessages} />} />
                <Route path="/todolist" element={<PrivateRoute element={ToDoList} />} />
                <Route path="/schedules" element={<PrivateRoute element={Schedules} />} />
                <Route path="/tags" element={<PrivateRoute element={Tags} />} />
                <Route path="/contacts" element={<PrivateRoute element={Contacts} />} />
                <Route path="/helps" element={<PrivateRoute element={Helps} />} />
                <Route path="/users" element={<PrivateRoute element={Users} />} />
                <Route path="/messages-api" element={<PrivateRoute element={MessagesAPI} />} />
                <Route path="/settings" element={<PrivateRoute element={SettingsCustom} />} />
                <Route path="/financeiro" element={<PrivateRoute element={Financeiro} />} />
                <Route path="/queues" element={<PrivateRoute element={Queues} />} />
                <Route path="/announcements" element={<PrivateRoute element={Annoucements} />} />
                <Route path="/subscription" element={<PrivateRoute element={Subscription} />} />
                <Route path="/chats/:id?" element={<PrivateRoute element={Chat} />} />
                {showCampaigns && (
                  <>
                    <Route path="/contact-lists" element={<PrivateRoute element={ContactLists} />} />
                    <Route path="/contact-lists/:contactListId/contacts" element={<PrivateRoute element={ContactListItems} />} />
                    <Route path="/campaigns" element={<PrivateRoute element={Campaigns} />} />
                    <Route path="/campaign/:campaignId/report" element={<PrivateRoute element={CampaignReport} />} />
                    <Route path="/campaigns-config" element={<PrivateRoute element={CampaignsConfig} />} />
                  </>
                )}
              </Routes>
            </LoggedInLayout>
          </WhatsAppsProvider>
          <ToastContainer autoClose={3000} />
        </TicketsContextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default RoutesComponent;