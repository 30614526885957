import React, { useState, useEffect } from "react";
import {
  Paper,
  Container,
  Grid,
  Chip,
  TextField,
  Button,
  Typography,
  Avatar,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { grey, blue } from "@mui/material/colors";
import { toast } from "react-toastify";

import SpeedIcon from "@mui/icons-material/Speed";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import Chart from "./Chart";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import CardCounter from "../../components/Dashboard/CardCounter";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray, isEmpty } from "lodash";
import useDashboard from "../../hooks/useDashboard";
import useCompanies from "../../hooks/useCompanies";
import moment from "moment";
import { socketConnection } from "../../services/socket";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: 240,
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  filtersSection: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    //backgroundColor: "#f4f6f8",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  dueDate: {
    textAlign: "right",
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
   // backgroundColor: "#f4f6f8",
    borderRadius: theme.shape.borderRadius,
  },
  avatarTransparent: {
    backgroundColor: "transparent",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [counters, setCounters] = useState({});
  const [attendants, setAttendants] = useState([]);
  const [filterType, setFilterType] = useState(2); // Start with "Período" by default
  const [period, setPeriod] = useState(3); // Start with the smallest period
  const [setCompanyDueDate] = useState();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [loading, setLoading] = useState(false);
  const { find } = useDashboard();
  const { finding } = useCompanies();

  useEffect(() => {
    async function firstLoad() {
      await fetchData();
    }
    firstLoad();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    let params = {};
    if (period > 0) {
      params = { days: period };
    }
    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = { ...params, date_from: moment(dateFrom).format("YYYY-MM-DD") };
    }
    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = { ...params, date_to: moment(dateTo).format("YYYY-MM-DD") };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(params);

    if (!data) {
      setLoading(false);
      return;
    }


    setCounters(data.counters);
    setAttendants(isArray(data.attendants) ? data.attendants : []);
    setLoading(false);
  };

  useEffect(() => {
    async function fetchData() {
      await loadCompanies();
    }
    fetchData();
  }, []);

  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await finding(localStorage.getItem("companyId"));
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    } catch (e) {}
    setLoading(false);
  };

  const handleFilterTypeChange = (type) => {
    setFilterType(type);
    if (type === 2) {
      setDateFrom("");
      setDateTo("");
      setPeriod(3); // Default to the smallest period
    }
  };

  const handlePeriodChange = async (value) => {
    setPeriod(value);
    await fetchData();
  };

  const renderPeriodChips = () => {
    const periods = [
      { label: "Últimos 3 dias", value: 3 },
      { label: "Últimos 7 dias", value: 7 },
      { label: "Últimos 15 dias", value: 15 },
      { label: "Últimos 30 dias", value: 30 },
      { label: "Últimos 60 dias", value: 60 },
      { label: "Últimos 90 dias", value: 90 },
    ];

    return (
      <Grid container justifyContent="center">
        {periods.map((item) => (
          <Chip
            key={item.value}
            label={item.label}
            onClick={() => handlePeriodChange(item.value)}
            color={period === item.value ? "primary" : "default"}
            className={classes.chip}
          />
        ))}
        <Chip
          label=  {filterType === 2 ? "Filtrar por Data" : "Filtrar por Período"}
          onClick={() => handleFilterTypeChange(filterType === 2 ? 1 : 2)} variant="contained"
          color={filterType === 1 ? "primary" : "default"}
          className={classes.chip}
        />
      </Grid>
    );
  };

  const renderDateFilters = () => (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Data Inicial"
          type="date"
          value={dateFrom}
          onChange={(e) => setDateFrom(e.target.value)}
          className={classes.fullWidth}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Data Final"
          type="date"
          value={dateTo}
          onChange={(e) => setDateTo(e.target.value)}
          className={classes.fullWidth}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} className={classes.alignRight}>
        <ButtonWithSpinner
          loading={loading}
          onClick={fetchData}
          variant="contained"
          color="primary"
        >
          Filtrar
        </ButtonWithSpinner>
      </Grid>
    </Grid>
  );

  const renderFilters = () => (
    <div className={classes.filtersSection}>
      {filterType === 2 ? renderPeriodChips() : renderDateFilters()}
    </div>
  );

  const formatTime = (minutes) =>
    moment().startOf("day").add(minutes, "minutes").format("HH[h] mm[m]");

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +userId) {
        toastError("Sua conta foi acessada em outro computador.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
    });

    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
  }, []);

  return (
    <Container maxWidth="lg" className={classes.container}>
    {/* Comentário: Este é o título do dashboard 
      <Grid container justifyContent="space-between" alignItems="center" className={classes.title}>
        <Grid item>
          <Typography variant="h4">Dashboard</Typography>
        </Grid>
        <Grid item>
          <CardCounter
            icon={<TodayIcon fontSize="inherit" />}
            title="Data Vencimento"
            value={companyDueDate}
            loading={loading}
          />
        </Grid>
     </Grid>

     */}

      <div className={classes.section}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Agora
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper className={classes.fixedHeightPaper}>
              <Chart />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CardCounter
                  icon={
                    <Avatar className={classes.avatarTransparent}>
                      <HourglassTopIcon fontSize="inherit" />
                    </Avatar>
                  }
                  title="Atendimentos Pendentes"
                  description="Número de atendimentos que ainda não foram atendidos por um atendente."
                  value={counters.supportPending}
                  loading={loading}
                 // style={{ backgroundColor: red[100] }}
                />
              </Grid>
              <Grid item xs={12}>
                <CardCounter
                  icon={
                    <Avatar className={classes.avatarTransparent}>
                      <SupportAgentIcon fontSize="inherit" />
                    </Avatar>
                  }
                  title="Atendimentos Acontecendo"
                  description="Número de atendimentos que estão sendo atendidos por um atendente."
                  value={counters.supportHappening}
                  loading={loading}
                //  style={{ backgroundColor: orange[100] }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className={classes.section}>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.sectionTitle}>
          <Grid item>
            <Typography variant="h6">Estatísticas</Typography>
          </Grid>
          {filterType !== 2 && (
            <Grid item>
              <Button onClick={() => handleFilterTypeChange(filterType === 2 ? 1 : 2)} variant="contained">
                {filterType === 2 ? "Filtrar por Data" : "Filtrar por Período"}
              </Button>
            </Grid>
          )}
        </Grid>
        {renderFilters()}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <CardCounter
              icon={
                <Avatar className={classes.avatarTransparent}>
                  <SpeedIcon fontSize="inherit" />
                </Avatar>
              }
              title="Duração atendimento"
              value={formatTime(counters.avgSupportTime)}
              description="Tempo médio em que um atendente leva para concluir o atendimento com um cliente, no período selecionado."
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardCounter
              icon={
                <Avatar className={classes.avatarTransparent}>
                  <SpeedIcon fontSize="inherit" />
                </Avatar>
              }
              title="Tempo de Espera"
              value={formatTime(counters.avgWaitTime)}
              description="Tempo médio que um cliente leva para ser atendido por um atendente, no período selecionado."
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardCounter
              icon={
                <Avatar className={classes.avatarTransparent}>
                  <PersonIcon fontSize="inherit" />
                </Avatar>
              }
              title="Potenciais Clientes"
              description="Número de leads cadastrados no sistema, no período selecionado."
              value={counters.leads}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardCounter
              icon={
                <Avatar className={classes.avatarTransparent}>
                  <AssignmentIcon fontSize="inherit" />
                </Avatar>
              }
              title="Atendimentos Feitos"
              description="Número de atendimentos realizados no período selecionado."
              value={counters.supportFinished}
              loading={loading}
              //style={{ backgroundColor: green[100] }}
            />
          </Grid>
        </Grid>
      </div>

      {attendants.length ? (
        <div className={classes.section}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Status dos Atendentes
          </Typography>
          <TableAttendantsStatus attendants={attendants} loading={loading} />
        </div>
      ) : null}
    </Container>
  );
};

export default Dashboard;
