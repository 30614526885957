import React, { useState, useEffect, useReducer, useRef } from "react";

import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import { green } from "@mui/material/colors";
import { blue } from "@mui/material/colors";

import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Avatar,
  Typography,
  Fab,
  Zoom,
} from "@mui/material";

import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
  Facebook,
  Instagram,
  KeyboardArrowDown,
} from "@mui/icons-material";

import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";
import whatsBackgroundDark from "../../assets/wa-background-dark.jpg";
import SendIcon from '@mui/icons-material/Send';
import NewTicketModal from "../NewTicketModal";
import ContactSearchModal from "../ContactSearchModal"; // Importar o modal de busca de contatos

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    minWidth: 300,
    minHeight: 200,
  },

  messagesList: {
    backgroundImage: `url(${theme.palette.mode === 'dark' ? whatsBackgroundDark : whatsBackground})`,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? "#111b21" : "#fff",

    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.mode === 'dark' ? "#111B21" : "#eee",

    },
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.mode === 'dark' ? "#424242" : "#ffffff",
    color: theme.palette.mode === 'dark' ? "#e0e0e0" : "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: theme.palette.mode === 'dark' ? "0 1px 1px #161616" : "0 1px 1px #b3b3b3",
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: theme.palette.mode === 'dark' ? "#616161" : "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    width: "100%",
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  quotedThumbnail: {
    maxWidth: "74px",
    maxHeight: "74px",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.mode === 'dark' ? "#0A5343" : "#dcf8c6",
    color: theme.palette.mode === 'dark' ? "#e0e0e0" : "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: theme.palette.mode === 'dark' ? "0 1px 1px #161616" : "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: theme.palette.mode === 'dark' ? "#02453a" : "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: theme.palette.mode === 'dark' ? "rgba(235, 235, 235, 0.36)" : "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemEdited: {
    overflowWrap: "break-word",
    padding: "3px 120px 6px 6px",
  },

  messageVideo: {
    width: 250,
    maxHeight: 445,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  messageMediaSticker: {
    backgroundColor: "unset",
    boxShadow: "unset",
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  timestampStickerLeft: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    padding: 5,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  timestampStickerRight: {
    backgroundColor: theme.palette.mode === 'dark' ? "#0A5343" : "#dcf8c6",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: theme.palette.mode === 'dark' ? "0 1px 1px #161616" : "0 1px 1px #b3b3b3",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: theme.palette.mode === 'dark' ? "#424242" : "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: theme.palette.mode === 'dark' ? "0 1px 1px #161616" : "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: blue[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  ackDoneReadIcon: {
    color: blue[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
  imageLocation: {
    position: 'relative',
    width: '100%',
    height: 80,
    borderRadius: 5
  },

  '@global': {
    '@keyframes wave': {
      '0%, 60%, 100%': {
        transform: 'initial',
      },
      '30%': {
        transform: 'translateY(-15px)',
      },
    },
  },
  wave: {
    position: 'relative',
    textAlign: 'center',
    height: "30px",
    marginTop: "10px",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dot: {
    display: "inline-block",
    width: "7px",
    height: "7px",
    borderRadius: "50%",
    marginRight: "3px",
    background: "#303131",
    animation: "wave 1.3s linear infinite",
    "&:nth-child(2)": {
      animationDelay: "-1.1s",
    },
    "&:nth-child(3)": {
      animationDelay: "-0.9s",
    }
  },
  '@keyframes wave': {
    '0%, 60%, 100%': {
      transform: 'initial',
    },
    '30%': {
      transform: 'translateY(-15px)',
    },
  },
  scrollToBottomFab: {
    position: "fixed",
    bottom: 80,
    right: 16,
    backgroundColor: theme.palette.mode === 'dark' ? "#dcf8c6" : "#0A5343",
    color: theme.palette.primary.contrastText,
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
  },
  imageContainer: {
    position: "relative",
    "& img": {
      transition: "transform 0.3s ease-in-out",
    },
    "&:hover img": {
      transform: "scale(1.1)",
    },
  },
  imageText: {
    textAlign: "center",
    marginTop: 5,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};


const MessagesList = ({ ticket, ticketId, isGroup }) => {
  const classes = useStyles();
  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();
  const [selectedMessage, setSelectedMessage] = useState({});
  const [selectedMessageData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);
  const socketRef = useRef(null);
  const [contactPresence, setContactPresence] = useState("available");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [initialSearchParam, setInitialSearchParam] = useState("");
  const [contactSearchModalOpen, setContactSearchModalOpen] = useState(false); // Estado para controlar a abertura do modal de busca de contatos

  // const socketManager = useContext(SocketContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
    setContactPresence("available");

    currentTicketId.current = ticketId;
  }, [ticketId]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        if (ticketId === undefined) return;
        try {
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber },
          });

          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            toBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    socketRef.current = socketConnection({ companyId });

    const socket = socketRef.current;

    socket.on("connect", () => {
      if (ticket?.id) {
        socket.emit("joinChatBox", `${ticket.id}`);
      }
    });

    socket.on(`company-${companyId}-appMessage`, (data) => {
      if (data.action === "create" && data.message.ticketId === currentTicketId.current) {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }

      if (data.action === "update" && data.message.ticketId === currentTicketId.current) {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data?.contact?.id === ticket?.contact?.id && data.action === "update") {
        setContactPresence(data?.contact?.presence || "available");
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, ticket]);

  useEffect(() => {
    toBottom();
  }, [messagesList]);
  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const toBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView();
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }

    setShowScrollButton(scrollTop + clientHeight < scrollHeight - 150);
  };

  const handleScrollToBottom = () => {
    scrollToBottom();
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleAddContactClick = (phone) => {
    setInitialSearchParam(phone.replace(/\D/g, ""));
    setNewTicketModalOpen(true);
  };

  
const parseVCard = (vCard) => {
  const lines = vCard.split('\n');
  const contact = {};
  lines.forEach(line => {
    if (line.startsWith('FN:')) {
      contact.name = line.substring(3);
    } else if (line.startsWith('TEL')) {
      contact.phone = line.substring(line.indexOf(':') + 1);
    } else if (line.startsWith('X-WA-BIZ-NAME:')) {
      contact.extra = line.substring(14);
    }
  });
  return contact;
};

const renderVCard = (vCard) => {
  const contacts = vCard.split('END:VCARD').filter(c => c.trim()).map(c => parseVCard(c + 'END:VCARD'));
  return contacts.map((contact, index) => (
    <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
      <Avatar style={{ marginRight: 10 }}>{contact.name.charAt(0)}</Avatar>
      <div>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>{contact.name}</Typography>
        <Typography variant="body2">{contact.phone}</Typography>
        {contact.extra && <Typography variant="body2" color="textSecondary">{contact.extra}</Typography>}
      </div>
      <IconButton aria-label="addContact" color="primary" style={{ marginLeft: 40 }} onClick={() => handleAddContactClick(contact.phone)}>
        <SendIcon />
      </IconButton>
    </div>
  ));
};


  const checkMessageMedia = (message, messageData) => {
    const data = JSON.parse(message.dataJson);
    const document =
      data?.message?.documentMessage
      || data?.message?.documentWithCaptionMessage?.message?.documentMessage;
    if (!document && message.mediaType === "image") {
      return (
        <>
          <div className={classes.imageContainer}>
            <ModalImageCors imageUrl={message.mediaUrl} isDeleted={message.isDeleted} />
            <div className={clsx({
              [classes.textContentItemDeleted]: message.isDeleted,
              [classes.textContentItem]: !message.isDeleted,
            })}>
              {data?.message?.imageMessage?.caption &&
                <>
                  <Divider />
                  <MarkdownWrapper>
                    {data.message.imageMessage.caption}
                  </MarkdownWrapper>
                </>
              }
            </div>
            <div className={classes.imageText}>Foto</div>
          </div>
        </>
      );
    }
    if (message.mediaType === "audio") {
      return (
        <>
          <audio controls>
            <source src={message.mediaUrl} type="audio/ogg"></source>
          </audio>
          <div className={[clsx({
            [classes.textContentItemDeleted]: message.isDeleted,
            [classes.textContentItem]: !message.isDeleted,
          }),]}>
            <MarkdownWrapper>
              {message.body}
            </MarkdownWrapper>
          </div>
        </>
      );
    }
    if (message.mediaType === "video") {
      return (
        <video
          className={classes.messageMedia}
          src={message.mediaUrl}
          controls
        />
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl.replace(/%/g, '%25')}
            >
              Download
            </Button>
          </div>
          {message.body !== document?.fileName &&
            <>
              <Divider />
              <div className={[clsx({
                [classes.textContentItemDeleted]: message.isDeleted,
              }),]}>
                <MarkdownWrapper>
                  {message.body}
                </MarkdownWrapper>
              </div>
            </>
          }
        </>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    const data = JSON.parse(message.quotedMsg.dataJson);

    const thumbnail = data?.message?.imageMessage?.jpegThumbnail;
    const stickerUrl = data?.message?.stickerMessage && message.quotedMsg?.mediaUrl;
    const imageUrl = thumbnail ? "data:image/png;base64, " + thumbnail : stickerUrl;
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}
          <MarkdownWrapper>{!message.quotedMsg?.mediaUrl?.endsWith(message.quotedMsg?.body) && message.quotedMsg?.body}</MarkdownWrapper>
        </div>
        {imageUrl && (
          <img className={classes.quotedThumbnail} src={imageUrl} />
        )}
      </div>
    );
  };

  const messageLocation = (message, createdAt) => {
    return (
      <div className={[classes.textContentItem, { display: 'flex', padding: 5 }]}>
        <img src={message.split('|')[0]} className={classes.imageLocation} alt="Location" />
        <a
          style={{ fontWeight: '700', color: 'gray' }}
          target="_blank"
          rel="noreferrer"
          href={message.split('|')[1]}> Clique para ver localização</a>
        <span className={classes.timestamp}>
          {format(parseISO(createdAt), "HH:mm")}
        </span>
      </div>
    )
  };


  const isVCard = (message) => {
    return message.startsWith('BEGIN:VCARD');
  };

  const vCard = (message) => {
    return (
      <div>
        {renderVCard(message)}
      </div>
    );
  };

  const renderMessages = () => {
    const viewMessagesList = messagesList.map((message, index) => {
      const data = JSON.parse(message.dataJson);
      const isSticker = data && data.message && ("stickerMessage" in data.message);
      const isEdited = message.isEdited || message.oldMessages?.length > 0;
      if (!message.fromMe) {
        return (
          <React.Fragment key={message.id}>
            {renderDailyTimestamps(message, index)}
            {renderMessageDivider(message, index)}
            <div
              className={[clsx(classes.messageLeft, {
                [classes.messageMediaSticker]: isSticker,
              })]}
              title={message.queueId && message.queue?.name}
            >
              <IconButton
                variant="contained"
                size="small"
                id="messageActionsButton"
                disabled={message.isDeleted}
                className={classes.messageActionsButton}
                onClick={(e) => handleOpenMessageOptionsMenu(e, message, data)}
              >
                <ExpandMore />
              </IconButton>
              {isGroup && (
                <span className={classes.messageContactName}>
                  {message.contact?.name}
                </span>
              )}

              {message.body.includes('data:image') ? messageLocation(message.body, message.createdAt)
                :
                isVCard(message.body) ?
                  <div
                    className={clsx(classes.textContentItem, {
                      [classes.textContentItemEdited]: isEdited,
                      marginRight: 0
                    })}>
                    {vCard(message.body)}
                  </div>

                  :

                  (<div className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                    [classes.textContentItemEdited]: isEdited
                  })}>
                    {message.quotedMsg && renderQuotedMessage(message)}
                    {!isSticker && (
                      message.mediaUrl ? ""
                        :
                        <>
                          {message.isDeleted && (
                            <Block
                              color="disabled"
                              fontSize="small"
                              className={classes.deletedIcon}
                            />
                          )}
                          <MarkdownWrapper>
                            {message.body}
                          </MarkdownWrapper>
                        </>
                    )}
                    <span className={clsx(classes.timestamp, {
                      [classes.timestampStickerLeft]: isSticker
                    })}>
                      {isEdited && <span>{i18n.t("message.edited")} </span>}
                      {format(parseISO(message.createdAt), "HH:mm")}
                    </span>
                  </div>)}
              {message.mediaUrl && checkMessageMedia(message, data)}
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={message.id}>
            {renderDailyTimestamps(message, index)}
            {renderMessageDivider(message, index)}
            <div
              className={clsx(classes.messageRight, {
                [classes.messageMediaSticker]: isSticker,
              })}
              title={message.queueId && message.queue?.name}
            >
              <IconButton
                variant="contained"
                size="small"
                id="messageActionsButton"
                disabled={message.isDeleted}
                className={classes.messageActionsButton}
                onClick={(e) => handleOpenMessageOptionsMenu(e, message, data)}
              >
                <ExpandMore />
              </IconButton>
              <div
                className={clsx(classes.textContentItem, {
                  [classes.textContentItemDeleted]: message.isDeleted,
                  [classes.textContentItemEdited]: isEdited,
                })}
              >
                {message.isDeleted && (
                  <Block
                    color="disabled"
                    fontSize="small"
                    className={classes.deletedIcon}
                  />
                )}
                {message.body.includes('data:image') ? messageLocation(message.body, message.createdAt)
                  :
                  isVCard(message.body) ?
                    <div className={[classes.textContentItem]}>
                      {vCard(message.body)}
                    </div>

                    :
                    message.quotedMsg && renderQuotedMessage(message)}
                {!isSticker && (
                  message.mediaUrl ? ""
                    :
                    <MarkdownWrapper>{message.body}</MarkdownWrapper>
                )}
                <span className={clsx(classes.timestamp, {
                  [classes.timestampStickerRight]: isSticker
                })}>
                  {isEdited && <span>{i18n.t("message.edited")} </span>}
                  {format(parseISO(message.createdAt), "HH:mm")}
                  {renderMessageAck(message)}
                </span>
              </div>
              {message.mediaUrl && checkMessageMedia(message, data)}
            </div>
          </React.Fragment>
        );
      }
    });
    return viewMessagesList;
  };

  const handleSendContact = async (contact) => {
    const message = {
      fromMe: true,
      body: `${contact.name}\n${contact.phone}`,
      mediaUrl: "",
      quotedMsg: null,
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
      setContactSearchModalOpen(false);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        data={selectedMessageData}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 ? renderMessages() : []}
        {contactPresence === "composing" && (
          <div className={classes.messageLeft}>
            <div className={classes.wave}>
              <span className={classes.dot}></span>
              <span className={classes.dot}></span>
              <span className={classes.dot}></span>
            </div>
          </div>
        )}
      </div>
      {showScrollButton && (
        <Zoom in={showScrollButton}>
          <Fab
            color="primary"
            size="small"
            onClick={handleScrollToBottom}
            className={classes.scrollToBottomFab}
          >
            <KeyboardArrowDown />
          </Fab>
        </Zoom>
      )}
      {(ticket?.channel === "facebook" || ticket?.channel === "instagram") && (
        <div
          style={{
            width: "100%",
            display: "flex",
            padding: "10px",
            alignItems: "center",
          }}
        >
          {ticket?.channel === "facebook" ? (
            <Facebook small />
          ) : (
            <Instagram small />
          )}

          <span>
            Você tem 24h para responder após receber uma mensagem, de acordo
            com as políticas da Meta.
          </span>
        </div>
      )}
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={() => setNewTicketModalOpen(false)}
        initialSearchParam={initialSearchParam}
      />
      <ContactSearchModal
        open={contactSearchModalOpen}
        onClose={() => setContactSearchModalOpen(false)}
        onSelectContact={handleSendContact}
      />
    </div>
  );
};

export default MessagesList;