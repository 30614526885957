import React, { useState } from "react";
import { TextField, Switch, FormControlLabel, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import useSettings from "../../hooks/useSettings";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  button: {
    alignSelf: "flex-start",
  },
}));

const CustomizationForm = ({ settings }) => {
  const classes = useStyles();
  const { update } = useSettings();
  const [companyName, setCompanyName] = useState(settings.find(s => s.key === "companyName")?.value || "");
  const [closeLink, setCloseLink] = useState(settings.find(s => s.key === "closeLink")?.value || "");
  const [activateLinkAction, setActivateLinkAction] = useState(settings.find(s => s.key === "activateLinkAction")?.value === "true");

  const handleSubmit = async () => {
    try {
      await update({ key: "companyName", value: companyName });
      await update({ key: "closeLink", value: closeLink });
      await update({ key: "activateLinkAction", value: activateLinkAction.toString() });
      toast.success("Configurações atualizadas com sucesso.");
    } catch (e) {
      toast.error("Erro ao atualizar configurações.");
    }
  };

  return (
    <div className={classes.container}>
      <TextField
        label="Nome da Empresa"
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
        fullWidth
      />
      <TextField
        label="Link para fechar atendimento"
        value={closeLink}
        onChange={(e) => setCloseLink(e.target.value)}
        fullWidth
      />
      <FormControlLabel
        control={
          <Switch
            checked={activateLinkAction}
            onChange={(e) => setActivateLinkAction(e.target.checked)}
          />
        }
        label="Deseja ativar ação do link ao fechar atendimento?"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className={classes.button}
      >
        Salvar
      </Button>
    </div>
  );
};

export default CustomizationForm;
