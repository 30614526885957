const config = loadJSON('/config.json');

function loadJSON(filePath) {
  // Load json file;
  var json = loadTextFileAjaxSync(filePath, "application/json");
  // Verifique se o conteúdo não é nulo e é um JSON válido
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error("Erro ao analisar JSON:", e);
    return null;
  }
}   

// Load text with Ajax synchronously: takes path to file and optional MIME type
function loadTextFileAjaxSync(filePath, mimeType)
{
  var xmlhttp=new XMLHttpRequest();
  xmlhttp.open("GET",filePath,false);
  if (mimeType != null) {
    if (xmlhttp.overrideMimeType) {
      xmlhttp.overrideMimeType(mimeType);
    }
  }
  xmlhttp.send();
  if (xmlhttp.status === 200 && xmlhttp.readyState === 4 )
  {
    return xmlhttp.responseText;
  }
  else {
    // TODO Throw exception
    return null;
  }
}

export function getBackendUrl() {
  return config && config.REACT_APP_BACKEND_URL ? config.REACT_APP_BACKEND_URL : process.env.REACT_APP_BACKEND_URL;
}

export default config;
