import { Chip, Paper, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { blue } from "@mui/material/colors";
import TagModal from "../TagModal"; 

export function TagsContainer({ ticket }) {

    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState([]);
    const isMounted = useRef(true);
    const [tagModalOpen, setTagModalOpen] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);

    const handleOpenTagModal = (tag) => {
        setSelectedTag(tag);
        setTagModalOpen(true);
    };

    const handleCloseTagModal = async () => {
        setTagModalOpen(false);
        if (selectedTag) {
            const updatedTag = await fetchTag(selectedTag.id);
            await updateTag(updatedTag);
        }
        setSelectedTag(null);
    };

    const fetchTag = async (tagId) => {
        try {
            const { data } = await api.get(`/tags/${tagId}`);
            return data;
        } catch (err) {
            toastError(err);
        }
    };

    const updateTag = async (updatedTag) => {
        setTags(prevTags => prevTags.map(tag => tag.id === updatedTag.id ? updatedTag : tag));
        setSelecteds(prevSelecteds => prevSelecteds.map(tag => tag.id === updatedTag.id ? updatedTag : tag));
        await syncTags({ ticketId: ticket.id, tags: selecteds });
    };

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (isMounted.current) {
            loadTags().then(() => {
                if (Array.isArray(ticket.tags)) {
                    setSelecteds(ticket.tags);
                } else {
                    setSelecteds([]);
                }
            });
        }
    }, [ticket]);

    const createTag = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags/list`);
            setTags(data);
        } catch (err) {
            toastError(err);
        }
    }

    const syncTags = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags/sync`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }

    const onChange = async (value, reason) => {
        let optionsChanged = [];
        if (reason === 'create-option' || (isArray(value) && value.some(item => isString(item)))) {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item });
                        optionsChanged.push(newTag);
                        setTags(prevTags => [...prevTags, newTag]); // Adiciona a nova tag à lista de tags
                    } else {
                        optionsChanged.push(item);
                    }
                }
            }
        } else {
            optionsChanged = value;
        }
        setSelecteds(optionsChanged);
        const validTags = optionsChanged.filter(tag => tag.id).map(tag => ({ id: tag.id, name: tag.name }));
        if (validTags.length > 0) {
            await syncTags({ ticketId: ticket.id, tags: validTags });
        }
    }

    return (
        <>
            <TagModal
                open={tagModalOpen}
                onClose={handleCloseTagModal}
                tagId={selectedTag && selectedTag.id}
            />
            <Paper 
                sx= {{
                    backgroundColor: 'transparent',
                    borderRadius: 0,
                    boxShadow: 'none',
                    backgroundImage: 'none',
                }}
            >
                <Autocomplete
                    multiple
                    size="small"
                    options={tags}
                    value={selecteds}
                    freeSolo
                    onChange={(e, v, r) => onChange(v, r)}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                style={{
                                    backgroundColor: option.color || blue[500],
                                    color: 'white',
                                    margin: '2px',
                                }}
                                label={option.name}
                                {...getTagProps({ index })}
                                size="small"
                                onClick={() => handleOpenTagModal(option)} // Adicionar manipulador de eventos onClick
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder={selecteds.length === 0 ? "🏷️ Etiquetas" : ""}
                            size="small"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderColor: "transparent",  // Borda transparente
                                    minWidth: 130,  // Aumenta o tamanho inicial do campo
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "transparent",  // Borda transparente
                                },
                                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "transparent",  // Borda transparente ao passar o mouse
                                },
                                "& .MuiInputBase-input": {
                                  //  color: (theme) => theme.palette.mode === 'dark' ? 'white' : 'black',  // Adapta a cor do texto ao modo noturno
                                },
                            }}

                        />
                    )}
                    PaperComponent={({ children }) => (
                        <Paper style={{ width: 200 }}>
                            {children}
                        </Paper>
                    )}
                />
            </Paper>
        </>
    );
}