import React from "react";
import { Chip, FormControl, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { i18n } from "../../translate/i18n";

const TicketsQueueSelect = ({
	userQueues,
	selectedQueueIds = [],
	onChange,
}) => {
	const handleToggleQueue = (queueId) => {
		// Adiciona ou remove a fila dos ids selecionados
		const newSelectedQueueIds = selectedQueueIds.includes(queueId)
			? selectedQueueIds.filter(id => id !== queueId)
			: [...selectedQueueIds, queueId];
		onChange(newSelectedQueueIds);
	};

	return (
		<div style={{ width: '100%', marginTop: -4 }}>
			<FormControl fullWidth margin="dense">
				<Box display="flex" flexWrap="wrap" gap={1} padding={1}>
					{userQueues?.length > 0 ? (
						userQueues.map(queue => (
							<Chip
								key={queue.id}
								label={queue.name}
								onClick={() => handleToggleQueue(queue.id)}
								color={selectedQueueIds.includes(queue.id) ? "primary" : "default"}
								style={{
									backgroundColor: selectedQueueIds.includes(queue.id) ? queue.color : undefined,
									color: selectedQueueIds.includes(queue.id) ? "#fff" : undefined,
								}}
								icon={selectedQueueIds.includes(queue.id) ? <CheckIcon style={{ color: "#fff" }} /> : null}
							/>
						))
					) : (
						<span>{i18n.t("ticketsQueueSelect.placeholder")}</span>
					)}
				</Box>
			</FormControl>
		</div>
	);
};

export default TicketsQueueSelect;
