import React, { useContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { IconButton, Chip } from "@mui/material";
import { MoreVert, Replay } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import Tooltip from '@mui/material/Tooltip';
import { green } from '@mui/material/colors';
import useCompanies from "../../hooks/useCompanies"; // Adicionado importação do hook useCompanies
import useSettings from "../../hooks/useSettings"; // Adicionado importação do hook useSettings

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));

const TicketActionButtonsCustom = ({ ticket }) => {
	const classes = useStyles();
	// const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const { setCurrentTicket } = useContext(TicketsContext);
	const [companyName, setCompanyName] = useState(""); // Adicionado estado para companyName
	const { find } = useCompanies(); // Adicionado hook useCompanies
	const [closeLink, setCloseLink] = useState(""); // Adicionado estado para closeLink
	const { getAll: getAllSettings } = useSettings(); // Adicionado hook useSettings
	const [activateLinkAction, setActivateLinkAction] = useState(false); // Adicionado estado para activateLinkAction

	const customTheme = createTheme({
		palette: {
		  	primary: green,
		}
	});


	useEffect(() => {
		async function fetchCompanyName() {
			try {
				const companyId = localStorage.getItem("companyId");
				const company = await find(companyId);
				setCompanyName(company.name); // Armazenar o nome da empresa
			} catch (e) {
				//toast.error(e);
			}
		}
		fetchCompanyName();
	}, [find]);

	useEffect(() => {
		async function fetchCloseLink() {
			try {
				const settingList = await getAllSettings();
				setCloseLink(settingList.find(s => s.key === "closeLink")?.value || ""); // Armazenar o closeLink
			} catch (e) {
			//	toast.error(e);
			}
		}
		fetchCloseLink();
	}, [getAllSettings]);

	useEffect(() => {
		async function fetchSettings() {
			try {
				const settingList = await getAllSettings();
				setCloseLink(settingList.find(s => s.key === "closeLink")?.value || ""); // Armazenar o closeLink
				setActivateLinkAction(settingList.find(s => s.key === "activateLinkAction")?.value === "true"); // Armazenar o activateLinkAction
			} catch (e) {
			//	toast.error(e);
			}
		}
		fetchSettings();
	}, [getAllSettings]);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		let estado = "opened";
			
		if (status === "open") {
			estado = "paused";
		} else if (status === "closed") {
			estado = "closed";
		}
		
		if(status=="open"){
			estado = "paused";
		} else if(status=="closed"){
			estado="closed";
		}
		 // Obtenha o número de telefone do ticket.contact
		 const phoneNumber = ticket.contact?.number;

		 if (phoneNumber && activateLinkAction) { // Verificar se activateLinkAction é verdadeiro
		   // Substitua (number) pelo número de telefone no URL do webhook
		   const webhookUrl = `${closeLink}?remoteJid=${phoneNumber}&status=${estado}`;
   
		   // Função assíncrona para chamar o webhook
		   const callWebhook = async () => {
			 try {
			   // Chame o webhook usando fetch
			   await fetch(webhookUrl, {
				 method: "GET", // Ou "POST" se necessário
			   });
   
			   console.log("Webhook chamado com sucesso");
			 } catch (error) {
			   console.error("Erro ao chamar o webhook:", error);
			   
			 }
		   };
   
		   // Chame a função assíncrona para chamar o webhook
		   await callWebhook();
		 }


		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});

			setLoading(false);
			if (status === "open") {
				setCurrentTicket({ ...ticket, code: "#open" });
			} else {
				setCurrentTicket({ id: null, code: null });
				//	navigate("/tickets", { replace: true }); // Use replace para garantir que a navegação recarregue a página
				//	if (status === "closed") {
				//		window.location.reload(); // Recarrega toda a aplicação (ToDo: Corrigir isso)
				//	}
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		(<div className={classes.actionButtons}>
			{ticket.status === "closed" && (
				<>
					<Chip
						loading={loading}
						icon={<Replay />}
						size="small"
						label={i18n.t("messagesList.header.buttons.reopen")}
						onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
					/>
				</>
			)}
			{ticket.status === "open" && (
				<>
					<Tooltip title={i18n.t("messagesList.header.buttons.return")}>
						<IconButton onClick={e => handleUpdateTicketStatus(e, "pending", null)} size="large">
							<UndoRoundedIcon />
						</IconButton>
					</Tooltip>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={customTheme}>
							<Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
								<IconButton
									onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
									color="primary"
									size="large">
									<CheckCircleIcon />
								</IconButton>
							</Tooltip>
						</ThemeProvider>
					</StyledEngineProvider>
					<IconButton onClick={handleOpenTicketOptionsMenu} size="large">
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>)
	);
};

export default TicketActionButtonsCustom;
