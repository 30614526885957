import React from "react";
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Tickets from "../TicketsCustom";
import TicketAdvanced from "../TicketsAdvanced";

function TicketResponsiveContainer() {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    if (isMdUp) {
        return <Tickets />;
    }
    return <TicketAdvanced />;
}

export default TicketResponsiveContainer;