import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";

import ScheduleModal from "../ScheduleModal";
import useCompanies from "../../hooks/useCompanies"; // Adicionado importação do hook useCompanies
import useSettings from "../../hooks/useSettings"; // Adicionado importação do hook useSettings

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
	const [contactId, setContactId] = useState(null);
	const [companyName, setCompanyName] = useState(""); // Adicionado estado para companyName
	const { find } = useCompanies(); // Adicionado hook useCompanies
	const [closeLink, setCloseLink] = useState(""); // Adicionado estado para closeLink
	const { getAll: getAllSettings } = useSettings(); // Adicionado hook useSettings
	const [activateLinkAction, setActivateLinkAction] = useState(false); // Adicionado estado para activateLinkAction

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		async function fetchCompanyName() {
			try {
				const companyId = localStorage.getItem("companyId");
				const company = await find(companyId);
				setCompanyName(company.name); // Armazenar o nome da empresa
			} catch (e) {
				toast.error(e);
			}
		}
		fetchCompanyName();
	}, [find]);

	useEffect(() => {
		async function fetchCloseLink() {
			try {
				const settingList = await getAllSettings();
				setCloseLink(settingList.find(s => s.key === "closeLink")?.value || ""); // Armazenar o closeLink
			} catch (e) {
				toast.error(e);
			}
		}
		fetchCloseLink();
	}, [getAllSettings]);

	useEffect(() => {
		async function fetchSettings() {
			try {
				const settingList = await getAllSettings();
				setCloseLink(settingList.find(s => s.key === "closeLink")?.value || ""); // Armazenar o closeLink
				setActivateLinkAction(settingList.find(s => s.key === "activateLinkAction")?.value === "true"); // Armazenar o activateLinkAction
			} catch (e) {
				toast.error(e);
			}
		}
		fetchSettings();
	}, [getAllSettings]);

	const handleDeleteTicket = async () => {
		try {
			await api.delete(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	//incluido para pausar bot
	
	const handleUpdateTypebot = async (status) => {
		let estado = "opened";

			if(status=="open"){
				estado = "paused";
			} else if(status=="closed"){
				estado="closed";
			}
			 // Obtenha o número de telefone do ticket.contact
			 const phoneNumber = ticket.contact?.number;

			 if (phoneNumber) {
			   // Substitua (number) pelo número de telefone no URL do webhook
			   const webhookUrl = `${closeLink}?remoteJid=${phoneNumber}&status=${estado}`;
	   
			   // Função assíncrona para chamar o webhook
			   const callWebhook = async () => {
				 try {		
				   // Chame o webhook usando fetch
				   await fetch(webhookUrl, {
					 method: "GET", // Ou "POST" se necessário
				   });
	   
				   console.log("Webhook chamado com sucesso");
				 } catch (error) {
				   console.error("Erro ao chamar o webhook:", error);

				   
				 }
			   };
	   
			   // Chame a função assíncrona para chamar o webhook
			   await callWebhook();
			 }
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
		handleClose();
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};

	const handleOpenScheduleModal = () => {
		handleClose();
		setContactId(ticket.contact.id);
		setScheduleModalOpen(true);
	}

	const handleCloseScheduleModal = () => {
		setScheduleModalOpen(false);
		setContactId(null);
	}

	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				 {activateLinkAction && ( // Mostrar botão apenas se activateLinkAction for verdadeiro
        <MenuItem onClick={() => {
           handleUpdateTypebot("open");
          toast.success("Comando para pausar robô enviado.");
        }}>
          {`Pausar Robô`} {/* Substituído pelo closeLink */}
        </MenuItem>
      )}
				<MenuItem onClick={handleOpenScheduleModal}>
					{i18n.t("ticketOptionsMenu.schedule")}
				</MenuItem>
				<MenuItem onClick={handleOpenTransferModal}>
					{i18n.t("ticketOptionsMenu.transfer")}
				</MenuItem>
				{/*
				<Can
					role={user.profile}
					perform="ticket-options:deleteTicket"
					yes={() => (
						<MenuItem onClick={handleOpenConfirmationModal}>
							{i18n.t("ticketOptionsMenu.delete")}
						</MenuItem>
					)}
				/>
				*/}
				
			</Menu>
			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${
					ticket.id
				} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
					ticket.contact.name
				}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<TransferTicketModalCustom
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
			/>
			<ScheduleModal
				open={scheduleModalOpen}
				onClose={handleCloseScheduleModal}
				aria-labelledby="form-dialog-title"
				contactId={contactId}
			/>
		</>
	);
};

export default TicketOptionsMenu;