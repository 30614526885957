import React, { useState, useContext, useEffect } from "react";
import { styled, useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Drawer,
  BottomNavigation,
  BottomNavigationAction,
  List,
 // Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  useMediaQuery,
  CssBaseline,
  Slide,
} from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
//import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
//import AccountCircle from "@mui/icons-material/AccountCircle";

import HomeIcon from "@mui/icons-material/Home";
//import NotificationsIcon from "@mui/icons-material/Notifications";
//import MenuIcon from "@mui/icons-material/Menu";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SyncIcon from "@mui/icons-material/Sync";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import MainListItems from "./MainListItems";
//import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import toastError from "../errors/toastError";
import AnnouncementsPopover from "../components/AnnouncementsPopover";

import logo from "../assets/logo.png";
//import { socketConnection } from "../services/socket";
//import ChatPopover from "../pages/Chat/ChatPopover";
import AboutModal from "../components/AboutModal";

import { io } from "socket.io-client";

const drawerWidth = 300;

const Main = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 56px)',
  },
}));

const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: "width 0.3s",
    backgroundColor: theme.palette.mode === 'dark' ? '#202c33' : '#f0f2f5',
    overflowX: "hidden",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...(!open && {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center", // Alterado para centralizar o IconButton
  padding: "0 0px",
  minHeight: "48px",
  transition: "padding 0.3s",
}));

const Content = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: "auto",
}));

const socketConnection = ({ companyId, userId }) => {
  return io(process.env.REACT_APP_BACKEND_URL, {
    query: { companyId, userId }
  });
};

const LoggedInLayout = ({ children, selectedIcon, setSelectedIcon }) => {
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading, user } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [bottomNavValue, setBottomNavValue] = useState(0);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [notificationsAnchor, setNotificationsAnchor] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    console.log("companyId", companyId);
    console.log("userId", userId);
    const socket = socketConnection({ companyId, userId }); // Adicione userId aqui

    if (companyId && userId) {
      console.log("Conectando ao socket");

      socket.on(`company-${companyId}-auth`, (data) => {
        console.log("company-auth", data);
        if (data.user && data.user.id === +userId) {
          console.log("data.user.id", data.user.id);
          console.log("userId", userId);
          console.log("data.user.id === userId", data.user.id === +userId);
          console.log("Usuário autenticado");
          toastError("Sua conta foi acessada em outro computador.");
          setTimeout(() => {
            localStorage.clear();
            window.location.reload();
          }, 1000);
        }
      });

      socket.emit("userStatus");
      const interval = setInterval(() => {
        socket.emit("userStatus");
      }, 1000 * 60 * 5);

      return () => {
        socket.disconnect();
        clearInterval(interval);
      };
    }
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleOpenAboutModal = () => {
    setAboutModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };


  const handleLogoutClick = () => {
    handleMenuClose();
    handleLogout();
  };
  
  //const handleMenuClick = (event) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);
  const handleNotificationsClick = (event) => {
    setNotificationsAnchor(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchor(null);
  };

  const drawerClose = () => {
    if (window.innerWidth < 600) {
      setDrawerOpen(false);
    }
  };

  if (loading) {
    return <BackdropLoading />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  const themeCustom = createTheme({
    palette: {
      mode: theme.palette.mode
    },
  });

  return (
    <ThemeProvider theme={themeCustom}>
      <CssBaseline />
      <Main>
         {/* Desktop Drawer */}
         {!isMobile && (
        <Slide direction="right" in={true} mountOnEnter unmountOnExit>
          <DrawerStyled
            variant={drawerVariant}
            open={drawerOpen}
            onClose={() => setDrawerOpen(!drawerOpen)}
          >
             {
             /*user.id &&
              <Typography variant="body2" style={{ textAlign: 'center' }}>
                {user.name.split(' ')[0]}
              </Typography>
              */
            }
            <List sx={{
              flex: 1,
              padding: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
              transition: "padding 0.3s",
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.mode === 'dark' ? "#202c33" : "#eee",
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.mode === 'dark' ? "#202c33" : "#fff",
              },
            }}>
              <MainListItems drawerClose={drawerClose} selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} />
            </List>
            <Divider />
            {user.id && <AnnouncementsPopover />}
            {/*user.id && <NotificationsPopOver /> */}
            <DrawerHeader>
              <IconButton size="large" onClick={handleMenu}>
                <Avatar src={logo} />
              </IconButton>
              {user.id &&

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={menuOpen}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleOpenUserModal}>
                  {i18n.t("mainDrawer.appBar.user.profile")}
                </MenuItem>
                <MenuItem onClick={handleOpenAboutModal}>
                  Sobre
                </MenuItem>
                <MenuItem onClick={handleClickLogout}>
                  {i18n.t("mainDrawer.appBar.user.logout")}
                </MenuItem>
              </Menu>
              }
            </DrawerHeader>
          </DrawerStyled>
        </Slide>
         )}
         {/* Mobile Bottom Navigation */}
        {isMobile && (
          <BottomNavigation
            value={bottomNavValue}
            onChange={(event, newValue) => setBottomNavValue(newValue)}
            showLabels
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: theme.zIndex.appBar,
            }}
          >
            <BottomNavigationAction
              label="Dashboard"
              icon={<HomeIcon />}
              onClick={() => {
                setSelectedIcon("dashboard");
                navigate("/");
              }}
            />
            <BottomNavigationAction
              label="Tickets"
              icon={<WhatsAppIcon />}
              onClick={() => {
                setSelectedIcon("tickets");
                navigate("/tickets");
              }}
            />
            <BottomNavigationAction
              label="Conexões"
              icon={<SyncIcon />}
              onClick={() => {
                setSelectedIcon("connections");
                navigate("/connections");
              }}
            />
            <BottomNavigationAction
              label="Mais"
              icon={<MoreHorizIcon />}
              onClick={handleNotificationsClick}
            />
          </BottomNavigation>
        )}

        {/* Menu for user actions */}
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={handleOpenUserModal}>
            {i18n.t("mainDrawer.appBar.user.profile")}
          </MenuItem>
          <MenuItem onClick={handleOpenAboutModal}>Sobre</MenuItem>
          <MenuItem onClick={handleLogoutClick}>
            {i18n.t("mainDrawer.appBar.user.logout")}
          </MenuItem>
        </Menu>
        <Menu
          anchorEl={notificationsAnchor}
          open={Boolean(notificationsAnchor)}
          onClose={handleNotificationsClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          PaperProps={{
            style: {
              maxHeight: '50vh',
              width: '100%',
              left: 0,
              right: 0,
              bottom: 56, // Altura do BottomNavigation
              position: 'fixed',
            },
          }}
        >
          <MenuItem onClick={() => navigate("/todolist")}>
            {i18n.t("mainDrawer.listItems.todolist")}
          </MenuItem>
          <MenuItem onClick={() => navigate("/queues")}>
            {i18n.t("mainDrawer.listItems.queues")}
          </MenuItem>
          <MenuItem onClick={() => navigate("/campaigns")}>
            {i18n.t("mainDrawer.listItems.campaigns")}
          </MenuItem>
          <MenuItem onClick={() => navigate("/announcements")}>
            {i18n.t("mainDrawer.listItems.annoucements")}
          </MenuItem>
          <MenuItem onClick={() => navigate("/settings")}>
            {i18n.t("mainDrawer.listItems.settings")}
          </MenuItem>
        </Menu>
        <UserModal
          open={userModalOpen}
          onClose={() => setUserModalOpen(false)}
          userId={user?.id}
        />
        <AboutModal
          open={aboutModalOpen}
          onClose={() => setAboutModalOpen(false)}
        />
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
          <Content sx={{ backgroundColor: 'primary' }}>
            {children ? children : null}
          </Content>
        </Slide>
      </Main>
    </ThemeProvider>
  );
};

export default LoggedInLayout;
