import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import Grid2 from "@mui/material/Grid2"; // Corrija a importação para o caminho correto
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import useSettings from "../../hooks/useSettings";
import { toast } from 'react-toastify';
import { makeStyles } from "@mui/styles";
import { grey, blue } from "@mui/material/colors";
import Title from "../Title";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
    background: theme.palette.mode === 'dark' ? "#424242" : "#f2f5f3",
    borderRadius: 4,
    width: "100%",
    "& .MuiTab-wrapper": {
      color: theme.palette.mode === 'dark' ? "#ffffff" : "#128c7e"
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    width: "100%",
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: theme.palette.mode === 'dark' ? "#424242" : "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: theme.palette.mode === 'dark' ? "#ffffff" : blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
}));

export default function Options(props) {
  const theme = useTheme();
  const { settings, scheduleTypeChanged } = props;
  const classes = useStyles(theme);
  const [userRating, setUserRating] = useState("disabled");
  const [scheduleType, setScheduleType] = useState("disabled");
  const [callType, setCallType] = useState("enabled");
  const [chatbotType, setChatbotType] = useState("");
  const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled");

  const [loadingUserRating, setLoadingUserRating] = useState(false);
  const [loadingScheduleType, setLoadingScheduleType] = useState(false);
  const [loadingCallType, setLoadingCallType] = useState(false);
  const [loadingChatbotType, setLoadingChatbotType] = useState(false);
  const [loadingCheckMsgIsGroup, setCheckMsgIsGroup] = useState(false);



  const { update } = useSettings();

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      const userRating = settings.find((s) => s.key === "userRating");
      if (userRating) {
        setUserRating(userRating.value);
      }
      const scheduleType = settings.find((s) => s.key === "scheduleType");
      if (scheduleType) {
        setScheduleType(scheduleType.value);
      }
      const callType = settings.find((s) => s.key === "call");
      if (callType) {
        setCallType(callType.value);
      }
      const CheckMsgIsGroup = settings.find((s) => s.key === "CheckMsgIsGroup");
      if (CheckMsgIsGroup) {
        setCheckMsgIsGroupType(CheckMsgIsGroup.value);
      }
      const chatbotType = settings.find((s) => s.key === "chatBotType");
      if (chatbotType) {
        setChatbotType(chatbotType.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  async function handleChangeUserRating(value) {
    setUserRating(value);
    setLoadingUserRating(true);
    await update({
      key: "userRating",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingUserRating(false);
  }

  async function handleScheduleType(value) {
    setScheduleType(value);
    setLoadingScheduleType(true);
    await update({
      key: "scheduleType",
      value,
    });
    toast.success('Operação atualizada com sucesso.', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light",
    });
    setLoadingScheduleType(false);
    if (typeof scheduleTypeChanged === "function") {
      scheduleTypeChanged(value);
    }
  }

  async function handleCallType(value) {
    setCallType(value);
    setLoadingCallType(true);
    await update({
      key: "call",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingCallType(false);
  }

  async function handleChatbotType(value) {
    setChatbotType(value);
    setLoadingChatbotType(true);
    await update({
      key: "chatBotType",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingChatbotType(false);
  }

  async function handleGroupType(value) {
    setCheckMsgIsGroupType(value);
    setCheckMsgIsGroup(true);
    await update({
      key: "CheckMsgIsGroup",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setCheckMsgIsGroup(false);
  }

  return (<>
    <Grid2 spacing={3} container>
      <Grid2 xs={12} sm={6} md={4} item> 
        <FormControl margin="dense" variant="outlined" fullWidth style={{ minWidth: 200 }}>
          <InputLabel >Avaliações</InputLabel>
          <Select
            as={Select}
            id="ratings-label"
            label="Avaliações"
            margin="dense"
            labelId="ratings-label"
            value={userRating}
            onChange={async (e) => {
              handleChangeUserRating(e.target.value);
            }}
          >
            <MenuItem value={"disabled"}>Desabilitadas</MenuItem>
            <MenuItem value={"enabled"}>Habilitadas</MenuItem>
          </Select>
          <FormHelperText>
            {loadingUserRating && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid2>
      <Grid2 xs={12} sm={6} md={4} item> {/* Substitua Grid por Grid2 */}
        <FormControl margin="dense" variant="outlined" fullWidth style={{ minWidth: 200 }}>
          <InputLabel>
            Gerenciamento de Expediente
          </InputLabel>
          <Select
            id="schedule-type-label"
            as={Select}
            label="Gerenciamento de Expediente"
            margin="dense"
            labelId="schedule-type-label"
            value={scheduleType}
            onChange={async (e) => {
              handleScheduleType(e.target.value);
            }}
          >
            <MenuItem value={"disabled"}>Desabilitado</MenuItem>
            <MenuItem value={"queue"}>Gerenciamento Por Fila</MenuItem>
            <MenuItem value={"company"}>Gerenciamento Por Empresa</MenuItem>
          </Select>
          <FormHelperText>
            {loadingScheduleType && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid2>
      <Grid2 xs={12} sm={6} md={4} item> {/* Substitua Grid por Grid2 */}
      <FormControl margin="dense" variant="outlined" fullWidth style={{ minWidth: 200 }}>
      <InputLabel >
            Ignorar Mensagens de Grupos
          </InputLabel>
          <Select
            as={Select}
            label="Ignorar Mensagens de Grupos"
            id="group-type-label"
            margin="dense"
            labelId="group-type-label"
            value={CheckMsgIsGroup}
            onChange={async (e) => {
              handleGroupType(e.target.value);
            }}
          >
            <MenuItem value={"disabled"}>Desativado</MenuItem>
            <MenuItem value={"enabled"}>Ativado</MenuItem>
          </Select>
          <FormHelperText>
            {loadingScheduleType && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid2>
      <Grid2 xs={12} sm={6} md={4} item> {/* Substitua Grid por Grid2 */}
      <FormControl margin="dense" variant="outlined" fullWidth style={{ minWidth: 200 }}>
      <InputLabel id="call-type-label">
            Aceitar Chamada
          </InputLabel>
          <Select
            label="Aceitar Chamada"
            labelId="call-type-label"
            value={callType}
            onChange={async (e) => {
              handleCallType(e.target.value);
            }}
          >
            <MenuItem value={"disabled"}>Não Aceitar</MenuItem>
            <MenuItem value={"enabled"}>Aceitar</MenuItem>
          </Select>
          <FormHelperText>
            {loadingCallType && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid2>
      <Grid2 xs={12} sm={6} md={4} item> {/* Substitua Grid por Grid2 */}
      <FormControl margin="dense" variant="outlined" fullWidth style={{ minWidth: 200 }}>
      <InputLabel id="chatbot-type-label">
            Tipo Chatbot
          </InputLabel>
          <Select
            label="Tipo Chatbot"
            labelId="chatbot-type-label"
            value={chatbotType}
            onChange={async (e) => {
              handleChatbotType(e.target.value);
            }}
          >
            <MenuItem value={"text"}>Texto</MenuItem>
            <MenuItem value={"button"}>Botão</MenuItem>
            <MenuItem value={"list"}>Lista</MenuItem>
          </Select>
          <FormHelperText>
            {loadingChatbotType && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid2>
    </Grid2>
  </>);
}
