import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import CreateIcon from '@mui/icons-material/Create';
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

import { i18n } from "../../translate/i18n";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import { ContactForm } from "../ContactForm";
import ContactModal from "../ContactModal";
import { ContactNotes } from "../ContactNotes";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        display: "flex",
        background: theme.palette.mode === 'dark' ? "#111B21" : "#eee", // Aplicando o tema
    },
    header: {
        display: "flex",
        backgroundColor: theme.palette.mode === 'dark' ? "black" : "white", // Aplicando o tema
        //	borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        minHeight: "73px",
        justifyContent: "flex-start",
    },
    content: {
        display: "flex",
        background: theme.palette.mode === 'dark' ? "#111B21" : "#eee", // Aplicando o tema
        flexDirection: "column",
        // padding: "8px 0px 8px 8px",
        height: "100%",
        overflowY: "scroll",
        ...theme.scrollbarStyles(theme), // Aplicando os estilos do scrollbar
    },

    contactAvatar: {
        margin: 15,
        width: 100,
        height: 100,
    },

    contactHeader: {
        display: "flex",
        padding: 8,
        flexDirection: "column",
        alignItems: "center", // Centralizando a foto
        justifyContent: "center",
        border: "transparent",
        "& > *": {
            margin: 4,
        },
    },

    contactDetails: {
        marginTop: 8,
        padding: 14,
        display: "flex",
        flexDirection: "column",
    },
    contactExtraInfo: {
        marginTop: 4,
        padding: 6,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ContactDrawer = ({ open, handleDrawerClose, contact, ticket, loading }) => {
    const classes = useStyles();

    const [modalOpen, setModalOpen] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [avatarPopupOpen, setAvatarPopupOpen] = useState(false); // Novo estado para controlar o popup do avatar

    useEffect(() => {
        setOpenForm(false);
    }, [open, contact]);

    const handleAvatarClick = () => {
        setAvatarPopupOpen(true); // Abre o popup ao clicar no avatar
    };

    const handleAvatarPopupClose = () => {
        setAvatarPopupOpen(false); // Fecha o popup
    };

    return (<>
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            PaperProps={{ style: { position: "absolute" } }}
            BackdropProps={{ style: { position: "absolute" } }}
            ModalProps={{
                container: document.getElementById("drawer-container"),
                style: { position: "absolute" },
            }}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.header}>
                <IconButton onClick={handleDrawerClose} size="large">
                    <CloseIcon />
                </IconButton>
                <Typography style={{ justifySelf: "center" }}>
                    {i18n.t("contactDrawer.header")}
                </Typography>

            </div>
            {loading ? (
                <ContactDrawerSkeleton classes={classes} />
            ) : (
                <div className={classes.content}>
                    <Paper square variant="outlined" className={classes.contactHeader}>
                        <Avatar
                            src={contact.profilePicUrl}
                            alt="contact_image"
                            style={{ width: 150, height: 150 }} // Exibindo a foto maior
                            onClick={handleAvatarClick} // Evento de clique para abrir o popup
                        />
                        <Typography variant="h6" onClick={() => setOpenForm(true)}>
                            {contact.name}
                            <CreateIcon style={{ fontSize: 16, marginLeft: 5 }} />
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                            <Link href={`tel:${contact.number}`}>{contact.number}</Link>
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                            <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
                        </Typography>
                        {(contact.id && openForm) && <ContactForm initialContact={contact} onCancel={() => setOpenForm(false)} />}
                    </Paper>
                    <Paper square variant="outlined" className={classes.contactDetails}>
                        <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
                            {i18n.t("ticketOptionsMenu.appointmentsModal.title")}
                        </Typography>
                        <ContactNotes ticket={ticket} />
                    </Paper>
                    <Paper square variant="outlined" className={classes.contactDetails}>
                        <ContactModal
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                            contactId={contact.id}
                        ></ContactModal>
                        <Typography variant="subtitle1">
                            {i18n.t("contactDrawer.extraInfo")}
                        </Typography>
                        {contact?.extraInfo?.map(info => (
                            <Paper
                                key={info.id}
                                square
                                variant="outlined"
                                className={classes.contactExtraInfo}
                            >
                                <InputLabel>{info.name}</InputLabel>
                                <Typography component="div" noWrap style={{ paddingTop: 2 }}>
                                    <MarkdownWrapper>{info.value}</MarkdownWrapper>
                                </Typography>

                            </Paper>
                        ))}
                    </Paper>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setModalOpen(!openForm)}
                        style={{ fontSize: 12, border: "none" }} // Removendo a borda
                    >
                        {i18n.t("contactDrawer.buttons.edit")}
                    </Button>
                </div>
            )}
        </Drawer>
        <Dialog
            open={avatarPopupOpen}
            onClose={handleAvatarPopupClose}
            TransitionComponent={Transition}
            PaperProps={{ 
                style: { 
                    minWidth: 400, 
                    minHeight: 400, 
                    overflow: 'hidden', 
                    borderRadius: 10, 
                    transition: 'transform 0.3s ease-in-out' 
                } 
            }} // Ajuste para evitar scroll e bordas arredondadas
        >
            <div style={{ position: 'relative', height: '100%' }}>
                <div style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    padding: '8px 16px', 
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                    color: 'white', 
                    zIndex: 1 
                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar src={contact.profilePicUrl} alt="Imagem do contato" style={{ width: 40, height: 40, marginRight: 8 }} />
                        <Typography variant="h6">{contact.name}</Typography>
                    </div>
                    <IconButton onClick={handleAvatarPopupClose} size="large" style={{ color: 'white' }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                {contact.profilePicUrl && !contact.profilePicUrl.includes("nopicture") ? (
                    <img src={contact.profilePicUrl} alt="Imagem do contato" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                ) : (
                    <Typography variant="h6" style={{ textAlign: 'center', marginTop: '50%' }}>
                        Sem foto de perfil ou a foto está privada
                    </Typography>
                )}
            </div>
        </Dialog>
    </>);
};

export default ContactDrawer;
