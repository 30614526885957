// src/theme/theme.js
import { createTheme } from '@mui/material/styles';
import { ptBR } from "@mui/material/locale";

const theme = (locale) =>
  createTheme(
    {
      cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
      },
      colorSchemes: { light: true, dark: true },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 600,
          lg: 1200,
          xl: 1536,
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: '12px',
              textTransform: 'none',
              fontWeight: 'bold',
              padding: '12px 24px',
              boxShadow: 'none',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: '#005bb5',
              },
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: '#0872b9',
              borderRadius: '12px',
              boxShadow: 'none',
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              backgroundColor: '#ffffff',
              borderRadius: '12px',
              boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: '16px',
              boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
              padding: '16px',
            },
          },
        },
      },
      palette: {
        primary: { main: '#0872b9', contrastText: '#ffffff' },
        secondary: { main: '#525252' },
        background: {
          default: '#f5f5f5',
          paper: '#ffffff',
        },
        surface: { main: '#ffffff' },
        error: { main: '#b00020' },
      },
      typography: {
        fontFamily: 'Roboto, sans-serif',
        h1: {
          fontSize: '2.125rem',
          fontWeight: 'bold',
          lineHeight: '2.5rem',
          letterSpacing: '0.15px',
        },
        h2: {
          fontSize: '1.75rem',
          fontWeight: 500,
          lineHeight: '2.25rem',
        },
        h3: { fontSize: '1.5rem', fontWeight: 500 },
        body1: { fontSize: '1rem', lineHeight: '1.5rem' },
        button: { fontSize: '1rem', textTransform: 'none', fontWeight: 'bold' },
      },
      shape: { borderRadius: 12 },
      scrollbarStyles: (theme) => ({
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.mode === 'dark' ? "#111B21" : "#eee",
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.mode === 'dark' ? "#111b21" : "#fff",
        },
      }),
    },
    locale === 'ptBR' ? ptBR : undefined
  );

export default theme;
