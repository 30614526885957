import React, { useState } from "react";
import { Avatar, Card, CardHeader, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@mui/styles";
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.background.paper,
        cursor: 'pointer' // Add cursor pointer to indicate clickability
    },
    cardAvatar: {
        fontSize: '55px',
        color: theme.palette.mode === 'dark' ? "#111B21" : "#eee",
        width: theme.spacing(7),
        height: theme.spacing(7)
    },
    cardTitle: {
        fontSize: '18px',
        color: theme.palette.text.primary
    },
    cardSubtitle: {
        color: theme.palette.mode === 'dark' ? grey[400] : grey[600],
        fontSize: '14px'
    }
}));

export default function CardCounter(props) {
    const { icon, title, value, loading, style, description } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Card className={classes.card} style={style} onClick={handleClickOpen}>
                <CardHeader
                    avatar={
                        <Avatar className={classes.cardAvatar}>
                            {loading ? <CircularProgress size={24} /> : icon}
                        </Avatar>
                    }
                    title={
                        loading ? <Skeleton width="60%" /> : 
                        <Typography variant="h6" component="h2" className={classes.cardTitle}>
                            {title}
                        </Typography>
                    }
                    subheader={
                        loading ? <Skeleton width="40%" /> : 
                        <Typography variant="subtitle1" component="p" className={classes.cardSubtitle}>
                            {value}
                        </Typography>
                    }
                />
            </Card>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent dividers>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                        <Avatar className={classes.cardAvatar} style={{ marginRight: '16px' }}>
                            {icon}
                        </Avatar>
                        <div>
                            <Typography variant="h6" component="h2">
                                {title}
                            </Typography>
                            <Typography variant="subtitle1" component="p">
                                {value}
                            </Typography>
                        </div>
                    </div>
                    {description && (
                        <Typography variant="body2" component="p">
                            {description}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}